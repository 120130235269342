var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-grid-container"},[_c('Grid',{style:('height: auto;maxWidth: 100%'),attrs:{"data-items":_vm.result,"sortable":true,"sort":_vm.sort,"columns":_vm.columnsData,"pageable":_vm.gridPageable,"resizable":true,"take":_vm.take,"skip":_vm.skip,"total":_vm.total,"loader":_vm.loading},on:{"sortchange":_vm.sortChangeHandler,"pagechange":_vm.pageChangeHandler},scopedSlots:_vm._u([{key:"viewEvidenceTemplate",fn:function({ props }){return [_c('td',{staticClass:"k-table-td worktype-column-td",attrs:{"role":"gridcell","colspan":"1"}},[(props.dataItem?.relationshipEvd)?_c('div',{staticClass:"relationships-evidence-contaier"},[_c('AddEvidence',{attrs:{"hasEvidenceButton":true,"evidenceFormStatus":_vm.getEvidenceFormStatus(
                `${_vm.relationshipEvdKey}${props.dataItem.relId}`
              ),"btnLoading":_vm.getBtnLoadingStatus(
                `${_vm.relationshipEvdKey}${props.dataItem.relId}`
              ),"isDisabled":false,"formName":'Relationship'},nativeOn:{"click":function($event){return _vm.toggleEvidenceForm(
                `${_vm.relationshipEvdKey}${props.dataItem.relId}`
              )}}})],1):_vm._e()])]}},{key:"actionButtonTemplate",fn:function({ props }){return [_c('td',{staticClass:"k-table-td worktype-column-td",attrs:{"role":"gridcell","colspan":"1"}},[_c('div',{staticClass:"relationship-button-container"},[_c('b-icon',{staticClass:"delete-icon",attrs:{"icon":"delete","size":"is-small","type":"is-danger"},nativeOn:{"click":function($event){return _vm.deleteRelationship(props.dataItem.relationshipId)}}})],1)])]}},{key:"sgaIdTemplate",fn:function({ props }){return [_c('td',{staticClass:"k-table-td worktype-column-td",attrs:{"role":"gridcell","colspan":"1"}},[_c('div',{staticClass:"relationship-button-container",on:{"click":function($event){return _vm.navigateToViewEntity(props.dataItem.sgaId)}}},[_vm._v(" "+_vm._s(props.dataItem.sgaId)+" ")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }