<template>
  <div class="data-grid-container">
    <div class="grid-toolbar">
      <div class="grid-toolbar-title">
        <p>Already Existing Entities</p>
      </div>
      <div class="grid-toolbar-filters">
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '430px' }"
            :placeholder="'Search by Entity Name or SGA ID or Request ID'"
            :value="searchWord"
            :inputPrefix="'prefix'"
            @input="globalSearchValue"
          >
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
      </div>
    </div>
    <Grid
      ref="grid-table"
      :style="{ height: '100%', minHeight: '300px' }"
      :data-items="gridData"
      :resizable="true"
      :sortable="true"
      :pageable="gridPageable"
      :sort="sort"
      :filter="filter"
      :take="take"
      :skip="skip"
      :total="totalRecords"
      class="grid-table"
      :columns="columns"
      :loader="loading"
      @pagechange="pageChangeHandler"
      @datastatechange="dataStateChange"
      @sortchange="sortChangeHandler"
    >
      <template v-slot:viewEntityTemplate="{ props }">
        <td
          v-if="props.dataItem.sgaId"
          class="k-table-td worktype-column-td"
          role="gridcell"
          colspan="1"
        >
          <div class="relationship-button-container">
            {{ props.dataItem.sgaId }}
          </div>
        </td>
      </template>
      <template v-slot:legalEntityTemplate="{ props }">
        <td
          v-if="props.dataItem.isUpdated"
          class="k-table-td"
          role="gridcell"
          colspan="1"
        >
          <div class="legal-entity-container">
            {{ props.dataItem.entityName }}
          </div>
        </td>
        <td v-else class="k-table-td" role="gridcell" colspan="1">
          <div class="legal-entity-container">
            {{ props.dataItem.entityName }}
          </div>
        </td>
      </template>
      <template v-slot:copyTemplate="{ props }">
        <td
          v-if="props.dataItem.isUpdated"
          class="k-table-td"
          role="gridcell"
          colspan="1"
        >
          <div class="copy-container">
            <b-icon
              class="copy-icon"
              @click.native="copyEntity(props.dataItem.sgaId)"
              icon="content-copy"
              size="is-small"
            >
            </b-icon>
          </div>
        </td>
        <td v-else class="k-table-td" role="gridcell" colspan="1">
          <div class="copy-container">
            <b-icon
              class="copy-icon"
              @click.native="copyEntity(props.dataItem.sgaId)"
              icon="content-copy"
              size="is-small"
            >
            </b-icon>
          </div>
        </td>
      </template>
    </Grid>
  </div>
</template>

<script>
import { process } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'
import { mapActions, mapState } from 'vuex'
import Snackbar from '@/components/Snackbar'

export default {
  name: 'ExistingEntitiesGrid',
  components: {
    Grid,
    'k-input': Input
  },

  props: {
    searchAlreadyExistEntity: String
  },

  data() {
    return {
      searchWord: this.searchQueryWithin,
      gridPageable: {
        buttonCount: 6,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      gridData: [],
      skip: 0,
      take: 10,
      sort: [],
      total: this.totalRecords,
      filter: {
        logic: 'and',
        filters: []
      },
      customFilter: null,
      group: [],
      expandedItems: [],
      selectedIds: new Set(),
      columns: [
        {
          field: 'sgaId',
          title: 'SGA ID',
          width: '150',
          cell: 'viewEntityTemplate',
          sortable: false
        },
        {
          field: 'requestId',
          title: 'Request ID',
          width: '75',
          sortable: false
        },
        {
          field: 'entityName',
          title: 'Legal Entity Name',
          width: '200px',
          cell: 'legalEntityTemplate'
        },
        {
          field: 'prevEntityName',
          title: 'Previous Name',
          width: '200px',
          sortable: false
        },
        {
          field: 'alias',
          title: 'Alias',
          width: '200px',
          sortable: false
        },
        {
          field: 'countryCode',
          title: 'Country Of Domicile',
          width: '100px',
          sortable: false
        },
        {
          field: 'countrySubCode',
          title: 'DOM State ID',
          width: '100px',
          sortable: false
        },
        {
          field: 'regCountryCode',
          title: 'Country Of Registry',
          width: '100px',
          sortable: false
        },
        {
          field: 'regCountrySubCode',
          title: 'REG State ID',
          width: '100px',
          sortable: false
        },
        {
          field: 'autoEnrolled',
          title: 'Auto Enrolled (SGA/BNY)',
          width: '100px',
          sortable: false
        },
        {
          field: 'Copy',
          width: '85px',
          cell: 'copyTemplate',
          sortable: false
        }
      ],
      dashboardDataItems: [],
      loading: this.isLoadingExistingEntityGrid,
      subscriptionDropdownData: [
        {
          id: 1,
          text: 'Yes',
          value: true
        },
        {
          id: 2,
          text: 'No',
          value: false
        }
      ]
    }
  },

  computed: {
    ...mapState('relationship', [
      'existingEntityData',
      'limit',
      'offset',
      'totalRecords',
      'searchQueryWithin',
      'isLoadingExistingEntityGrid'
    ])
  },
  watch: {
    existingEntityData: {
      handler() {
        this.updateGridData(this.existingEntityData)
      },
      immediate: true
    },

    isLoadingExistingEntityGrid(newVal) {
      this.loading = newVal
    },

    limit(newVal) {
      this.take = newVal
    },

    offset(newVal) {
      this.skip = newVal
    },

    totalRecords(newVal) {
      this.total = newVal
    }
  },
  async mounted() {
    try {
      await this.resetPaginationData()
      await this.getExistingEntity(this.searchAlreadyExistEntity)
    } catch (error) {
      console.log('ERROR IN MOUNTED IS ', error)
    }
  },
  methods: {
    ...mapActions('relationship', [
      'getExistingEntity',
      'resetPaginationData',
      'updateSearchQueryWithin',
      'setExistingEntityLoader',
      'setLimitAndOffset',
      'setSortAndOrder'
    ]),

    processGridData(data, skipPagination = false) {
      return process(data, {
        take: skipPagination ? data.length : this.take,
        skip: skipPagination ? 0 : 0, // hardcoding skip to 0 as data is fetched through server side pagination
        sort: this.sort,
        group: this.group,
        filter: this.filter
      })
    },

    async refreshGridData() {
      await this.getExistingEntity(this.searchAlreadyExistEntity)
    },

    pageChangeHandler: async function (event) {
      this.skip = event.page.skip // offset
      this.take = event.page.take // limit
      this.setLimitAndOffset({ limit: this.take, offset: this.skip })
      await this.getExistingEntity(this.searchAlreadyExistEntity)
    },

    async sortChangeHandler(event) {
      console.log('sortChangeHandler', event.sort)
      this.sort = event.sort
      this.setSortAndOrder({
        sort: this.sort[0].field || '',
        order: this.sort[0].dir || ''
      })
      await this.getExistingEntity(this.searchAlreadyExistEntity)
    },

    async globalSearchValue(event) {
      const searchQueryWithinValue = event.target.value?.trim()
      console.log('globalSearchValue', searchQueryWithinValue)
      this.updateSearchQueryWithin(searchQueryWithinValue)
      this.setLimitAndOffset({ limit: this.take, offset: 0 })
      await this.getExistingEntity(this.searchAlreadyExistEntity)
    },

    dataStateChange(event) {
      if (!event.event?.field) {
        // Skipped the filter process on title click
        return
      }
      this.filter = event.data.filter || this.filter
      this.take = event.data.take
      this.skip = event.data.skip
      this.sort = event.data.sort
      this.group = event.data.group

      this.setLimitAndOffset({ limit: this.take, offset: this.skip })
      // Check if filters are cleared and reset grid data accordingly
      if (!event.data.filter || event.data.filter.filters.length === 0) {
        this.getExistingEntity(this.searchAlreadyExistEntity)
        // Reset to initial data or fetch again as needed
        this.updateGridData(this.dashboardDataItems)
      } else {
        this.getExistingEntity(this.searchAlreadyExistEntity)
      }
    },

    updateGridData(data) {
      const processedData = this.processGridData(data)
      this.gridData = processedData
    },

    async refreshDashboardData() {
      this.setExistingEntityLoader(true)
      await this.getexistingEntity(this.searchAlreadyExistEntity)
    },

    copyEntity(sgaId) {
      navigator.clipboard.writeText(sgaId)
      Snackbar({
        message: `Copied ${sgaId}.`,
        type: 'is-success',
        duration: 3000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.data-grid-container {
  margin-left: 27px;
  margin-right: 27px;
  display: flex;
  flex-direction: column;
  height: 100%;

  ::v-deep .k-grid td,
  .k-grid .k-table-td {
    border-block-end-width: 1px;
  }
  ::v-deep .mdi-flag::before {
    content: '\F023B';
    font-size: 25px;
  }

  ::v-deep .k-grid-md td,
  .k-grid-md .k-table-td {
    padding: 5px 12px;
  }

  .grid-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    border: none;

    .grid-toolbar-title {
      p {
        text-align: left;
        font-weight: 600;
        font-style: normal;
        font-size: 28px;
        line-height: 15px;
        font-family: Quicksand;
        letter-spacing: 0px;
        color: #00218a;
        opacity: 1;
        line-height: 1.5rem;
      }
    }

    .grid-toolbar-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .dropdown-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.6rem;
        width: 100%;

        ::v-deep .data-source-container {
          display: flex;
          align-items: center;

          .top-label {
            font-size: 14px;
            line-height: 20px;
            font-family: Quicksand;
            font-weight: 600;
            font-style: normal;
            color: #7d7d7d;
            margin-right: 0.6rem;
            margin-top: 0.3rem;
          }
          .dropdown-wrap {
            // height: 40px;
            .data-source-header {
              background-color: #ffffff;
              border: 1px solid #cccccc;

              // color: black;
              .button-container {
                .ellipsis {
                  color: black;
                  font-size: 14px;
                  line-height: 20px;
                  font-family: Quicksand;
                  font-weight: 600;
                  font-style: normal;
                }
                svg path {
                  stroke-width: 1;
                  stroke: black;
                }
              }
            }

            .data-source-abs {
              background: #ffffff 0% 0% no-repeat padding-box;
              color: #000000;

              .custom-checkbox {
                p {
                  color: #000000;
                }
              }

              .custom-checkbox:hover {
                background: rgba(196, 200, 248, 0.3) 0% 0% no-repeat padding-box;
              }
            }
          }
        }
      }

      ::v-deep .k-grid-search {
        height: 36px;
        width: 100%;
        .k-input {
          border: 1px solid #cccccc;

          input {
            // height: 44px;
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            font-family: Quicksand;
            font-style: normal;
          }

          .k-input-inner {
            margin-left: 0.1rem;
          }

          .k-input-prefix {
            margin-left: 0.5rem;
            color: #cccccc;
          }
        }
      }
    }
  }

  .legal-entity-container {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

::v-deep .k-alt {
  background-color: none;
}

::v-deep .highlight-row {
  background-color: rgb(245, 245, 245);
}

::v-deep .k-column-title {
  font-size: 14px;
  font-family: Quicksand;
  color: #000000;
  letter-spacing: 0.18px;
}

::v-deep .k-table-td {
  font-size: 14px;
  letter-spacing: 0.16px;
  color: #000000;
}

::v-deep .k-table-thead {
  background: #dfdfdf 0% 0% no-repeat padding-box;
}

.copy-container {
  .copy-icon {
    cursor: pointer;
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .data-grid-container {
    .grid-toolbar {
      .grid-toolbar-title {
        p {
          font-size: 31px;
          line-height: 23px;
        }
      }

      .grid-toolbar-filters {
        .dropdown-container {
          ::v-deep .data-source-container {
            .top-label {
              font-size: 16px;
            }

            .dropdown-wrap {
              .data-source-header {
                .button-container {
                  .ellipsis {
                    font-size: 16px;
                  }
                }
              }

              .data-source-abs {
                .custom-checkbox {
                  font-size: 16px;
                }
              }
            }
          }
        }

        ::v-deep .k-grid-search {
          .k-input {
            input {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  ::v-deep .k-column-title {
    font-size: 16px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
  }

  ::v-deep .k-table-td {
    font-size: 15px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}

@media (min-width: 1700px) {
  .data-grid-container {
    .grid-toolbar {
      .grid-toolbar-title {
        p {
          font-size: 36px;
          line-height: 23px;
        }
      }

      .grid-toolbar-filters {
        .dropdown-container {
          ::v-deep .data-source-container {
            .top-label {
              font-size: 18px;
            }

            .dropdown-wrap {
              .data-source-header {
                .button-container {
                  .ellipsis {
                    font-size: 18px;
                  }
                }
              }

              .data-source-abs {
                .custom-checkbox {
                  font-size: 18px;
                }
              }
            }
          }
        }

        ::v-deep .k-grid-search {
          .k-input {
            input {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  ::v-deep .k-column-title {
    font-size: 18px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
  }

  ::v-deep .k-table-td {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}
</style>
