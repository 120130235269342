<template>
  <div class="basic-details-section">
    <h2 class="section-title">Basic Details</h2>
    <div class="mandatory-note">
      <p>
        Text boxes marked as
        <span class="mandatory">*</span> are mandatory.
      </p>
    </div>
    <div></div>
    <div>
      <div class="basic-form">
        <div class="local-loader-container" v-if="loading">
          <div class="inava-loader"></div>
        </div>
        <div class="column-view">
          <input-vue
            :label="'SGA ID'"
            :mandatory="true"
            :value="sgaId"
            placeholder="SGA ID"
            class="form-input-field"
            :disabled="true"
            :class="sgaId ? 'form-control-disabled' : ''"
          />
        </div>
        <div class="column-view" />
        <div class="column-view">
          <input-vue
            label="Legal Entity Name"
            :value="newBasicDetails.legalEntityName"
            placeholder="Enter Legal Entity Name"
            :required="true"
            @onChangeName="updateEntityName"
            :min="1"
            :max="500"
            class="form-input-field"
            :isDefaultErrorMessage="isMandatorySubmit"
            :showSecondaryError="showLegalEntityNameEvidenceError"
            :secondaryError="evidenceIdErrors.legalEntityNameEvidenceId"
            :isAsciiEnglishCharactersOnly="true"
            :submitted="isMandatorySubmit"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(basicDetailEvidenceId.LEGAL_ENTITY_NAME)
              "
              @click.native="
                toggleEvidenceForm(basicDetailEvidenceId.LEGAL_ENTITY_NAME)
              "
              :btnLoading="
                getBtnLoadingStatus(basicDetailEvidenceId.LEGAL_ENTITY_NAME)
              "
              :isDisabled="isEvidenceBtnDisabled('legalEntityName')"
            />
          </div>
        </div>
        <div class="column-view">
          <input-vue
            label="Previous Entity Name"
            :value="newBasicDetails.previousEntityName"
            @onChangeName="updatePreviousEntityName"
            :min="1"
            :max="4000"
            placeholder="Enter Previous Entity Name"
            class="form-input-field"
            :isDefaultErrorMessage="isMandatorySubmit"
            :showSecondaryError="showPreviousEntityNameEvidenceError"
            :secondaryError="evidenceIdErrors.previousEntityNameEvidenceId"
            :submitted="isMandatorySubmit"
            :isAsciiEnglishCharactersOnly="true"
            :is-tooltip="true"
            :tooltip-msg="getTooltipMsg('Previous Entity Name')"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(
                  basicDetailEvidenceId.PREVIOUS_ENTITY_NAME
                )
              "
              @click.native="
                toggleEvidenceForm(basicDetailEvidenceId.PREVIOUS_ENTITY_NAME)
              "
              :btnLoading="
                getBtnLoadingStatus(basicDetailEvidenceId.PREVIOUS_ENTITY_NAME)
              "
              :isDisabled="isEvidenceBtnDisabled('previousEntityName')"
            />
          </div>
        </div>
        <div class="column-view">
          <SearchableSelect
            :value="newBasicDetails.legalStructure"
            label="Legal Structure"
            :placeholder="'Select Legal Structure'"
            :data="legaStructures"
            value_field="value"
            text_field="text"
            @onChangeName="updateLegalStructure"
            class="form-input-field"
            :showSecondaryError="showLegalStructureEvidenceError"
            :secondaryError="getLegalStructureEvidenceError"
            :mandatorySubmit="isMandatorySubmit"
            :submitted="submitted"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(basicDetailEvidenceId.LEGAL_STRUCTURE)
              "
              @click.native="
                toggleEvidenceForm(basicDetailEvidenceId.LEGAL_STRUCTURE)
              "
              :btnLoading="
                getBtnLoadingStatus(basicDetailEvidenceId.LEGAL_STRUCTURE)
              "
              :isDisabled="isEvidenceBtnDisabled('legalStructure')"
            />
          </div>
        </div>
        <div class="column-view">
          <input-vue
            inputId="alias"
            label="Alias"
            :value="newBasicDetails.alias"
            @onChangeName="updateAlias"
            :min="1"
            :max="4000"
            class="form-input-field"
            :isDefaultErrorMessage="isMandatorySubmit"
            :showSecondaryError="showAliasEvidenceError"
            :secondaryError="getAliasEvidenceError"
            :submitted="isMandatorySubmit"
            :isAsciiEnglishCharactersOnly="true"
            placeholder="Enter Alias Names"
            :is-tooltip="true"
            :tooltip-msg="getTooltipMsg('Alias')"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(basicDetailEvidenceId.ALIAS)
              "
              :btnLoading="getBtnLoadingStatus(basicDetailEvidenceId.ALIAS)"
              @click.native="toggleEvidenceForm(basicDetailEvidenceId.ALIAS)"
              :isDisabled="isEvidenceBtnDisabled('alias')"
            />
          </div>
        </div>

        <div class="column-view">
          <SearchableSelect
            :value="newBasicDetails.entityTypeValue"
            label="Entity Type"
            :required="true"
            :placeholder="'Select Entity type'"
            value_field="value"
            text_field="text"
            class="form-input-field"
            @onChangeName="updateEntityType"
            :data="entityTypeList"
            :submitted="submitted"
            :showSecondaryError="showEntityTypeEvidenceError"
            :secondaryError="evidenceIdErrors.entityTypeEvidenceId"
            :mandatorySubmit="isMandatorySubmit"
            :isMandatory="true"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(basicDetailEvidenceId.ENTITY_TYPE)
              "
              @click.native="
                toggleEvidenceForm(basicDetailEvidenceId.ENTITY_TYPE)
              "
              :btnLoading="
                getBtnLoadingStatus(basicDetailEvidenceId.ENTITY_TYPE)
              "
              :isDisabled="isEvidenceBtnDisabled('entityTypeValue')"
            />
          </div>
        </div>
        <div class="column-view">
          <SearchableSelect
            :value="newBasicDetails.entitySubTypeValue"
            label="Entity Sub-type"
            :required="true"
            :placeholder="'Select Entity Sub-Type'"
            value_field="value"
            text_field="text"
            :isMandatory="true"
            class="form-input-field"
            :showSecondaryError="showEntitySubTypeEvidenceError"
            :secondaryError="evidenceIdErrors.entitySubTypeEvidenceId"
            @onChangeName="updateEntitySubType"
            :submitted="submitted"
            :mandatorySubmit="isMandatorySubmit"
            :data="entitySubTypeList"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(basicDetailEvidenceId.ENTITY_SUB_TYPE)
              "
              @click.native="
                toggleEvidenceForm(basicDetailEvidenceId.ENTITY_SUB_TYPE)
              "
              :btnLoading="
                getBtnLoadingStatus(basicDetailEvidenceId.ENTITY_SUB_TYPE)
              "
              :isDisabled="isEvidenceBtnDisabled('entitySubTypeValue')"
            />
          </div>
        </div>
        <div class="column-view">
          <SearchableSelect
            :value="newBasicDetails.businessStatus"
            label="Business Status"
            :placeholder="'Select Business Status'"
            :data="businessStatusOptions"
            value_field="value"
            text_field="text"
            :showSecondaryError="showBusinessStatusEvidenceError"
            :secondaryError="getBusinessStatusEvidenceError"
            @onChangeName="updateBusinessStatus"
            class="form-input-field"
            :mandatorySubmit="isMandatorySubmit"
            :submitted="submitted"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(basicDetailEvidenceId.BUSINESS_STATUS)
              "
              @click.native="
                toggleEvidenceForm(basicDetailEvidenceId.BUSINESS_STATUS)
              "
              :btnLoading="
                getBtnLoadingStatus(basicDetailEvidenceId.BUSINESS_STATUS)
              "
              :isDisabled="isEvidenceBtnDisabled('businessStatus')"
            />
          </div>
        </div>

        <div class="column-view">
          <SearchableSelect
            :value="newBasicDetails.businessStatusInactiveReason"
            label="Reason"
            :placeholder="'Select Reason'"
            :data="inactivationReasons"
            value_field="value"
            text_field="text"
            @onChangeName="updateBusinessStatusInactiveReason"
            class="form-input-field"
            :isMandatory="isBusinessInactiveReasonRequired"
            :mandatorySubmit="isMandatorySubmit"
            :required="isBusinessInactiveReasonRequired"
            :submitted="submitted"
          />
        </div>

        <div class="column-view">
          <SearchableSelect
            :value="newBasicDetails.usGseFlag"
            label="US GSE Flag"
            :required="true"
            :placeholder="'Select US GSE Flag'"
            :data="enrichmentAnswerChoices"
            value_field="value"
            text_field="text"
            @onChangeName="updateUSGseFlag"
            class="form-input-field"
            :showSecondaryError="showUsGseFlagEvidenceError"
            :secondaryError="evidenceIdErrors.usGseFlagEvidenceId"
            :isMandatory="true"
            :mandatorySubmit="isMandatorySubmit"
            :submitted="submitted"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(basicDetailEvidenceId.US_GSE_FLAG)
              "
              @click.native="
                toggleEvidenceForm(basicDetailEvidenceId.US_GSE_FLAG)
              "
              :btnLoading="
                getBtnLoadingStatus(basicDetailEvidenceId.US_GSE_FLAG)
              "
              :isDisabled="isEvidenceBtnDisabled('usGseFlag')"
            />
          </div>
        </div>

        <div class="column-view">
          <SearchableSelect
            :value="newBasicDetails.branchIndicator"
            label="Branch Indicator"
            :placeholder="'Select Branch Indicator'"
            :data="enrichmentAnswerChoices"
            value_field="value"
            text_field="text"
            @onChangeName="updateBranchIndicator"
            class="form-input-field"
            :showSecondaryError="showBranchIndicatorEvidenceError"
            :secondaryError="getBranchIndicatorEvidenceError"
            :mandatorySubmit="isMandatorySubmit"
            :submitted="submitted"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(basicDetailEvidenceId.BRANCH_INDICATOR)
              "
              @click.native="
                toggleEvidenceForm(basicDetailEvidenceId.BRANCH_INDICATOR)
              "
              :btnLoading="
                getBtnLoadingStatus(basicDetailEvidenceId.BRANCH_INDICATOR)
              "
              :isDisabled="isEvidenceBtnDisabled('branchIndicator')"
            />
          </div>
        </div>
      </div>
    </div>
    <template>
      <ActionButton
        @SaveData="saveData"
        @saveAndNext="saveAndNext"
        @goBack="goBack"
        :isFormValid="isValidForm"
      >
      </ActionButton>
    </template>
    <template>
      <confirmation-dialog
        :visible="confirmationDialogData.visible"
        :title="confirmationDialogData.title"
        :bodyText="confirmationDialogData.text"
        :cancelButtonText="confirmationDialogData.cancelButtonText"
        :confirmButtonText="confirmationDialogData.confirmButtonText"
        :closeDialog="closeDialog"
        :triggerFunction="confirmationDialogData.triggerFunction"
      />
    </template>
  </div>
</template>

<script>
import ActionButton from '@/components/DMP/ActionButton.vue'
import AddEvidence from '@/components/DMP/AddEvidence.vue'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import SearchableSelect from '@/components/DMP/SearchableSelect'
import { activeStepIndexName, basicDetailEvidenceId } from '@/constant/data'
import { isValidEnglishASCII } from '@/util/util'
import InputVue from '@/views/Admin/components/Input.vue'
import { isEqual } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'BasicDetailsForm',

  components: {
    SearchableSelect,
    ActionButton,
    AddEvidence,
    InputVue,
    ConfirmationDialog
  },

  props: {
    // Define the updateStepper prop
    updateStepper: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      sgaId: '',
      loading: false,
      newBasicDetails: {
        legalEntityName: '',
        alias: '',
        previousEntityName: '',
        legalStructure: null,
        branchIndicator: null,
        usGseFlag: null,
        entityTypeValue: null,
        entitySubTypeValue: null,
        businessStatus: null,
        businessStatusInactiveReason: null,
        aliasEvidenceId: null,
        legalEntityNameEvidenceId: null,
        previousEntityNameEvidenceId: null,
        legalStructureEvidenceId: null,
        entityTypeEvidenceId: null,
        entitySubTypeEvidenceId: null,
        businessStatusEvidenceId: null,
        usGseFlagEvidenceId: null,
        branchIndicatorEvidenceId: null
      },
      submitted: false,
      businessStatusOptions: [
        {
          value: true,
          text: 'Active'
        },
        {
          value: false,
          text: 'Inactive'
        }
      ],
      basicDetailEvidenceId,
      confirmationDialogData: {
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      },
      activeStepIndexName,
      evidenceIdKeyMapping: {
        [basicDetailEvidenceId.ALIAS]: 'aliasEvidenceId',
        [basicDetailEvidenceId.LEGAL_ENTITY_NAME]: 'legalEntityNameEvidenceId',
        [basicDetailEvidenceId.PREVIOUS_ENTITY_NAME]:
          'previousEntityNameEvidenceId',
        [basicDetailEvidenceId.LEGAL_STRUCTURE]: 'legalStructureEvidenceId',
        [basicDetailEvidenceId.ENTITY_TYPE]: 'entityTypeEvidenceId',
        [basicDetailEvidenceId.ENTITY_SUB_TYPE]: 'entitySubTypeEvidenceId',
        [basicDetailEvidenceId.BUSINESS_STATUS]: 'businessStatusEvidenceId',
        [basicDetailEvidenceId.US_GSE_FLAG]: 'usGseFlagEvidenceId',
        [basicDetailEvidenceId.BRANCH_INDICATOR]: 'branchIndicatorEvidenceId'
      },
      evidenceIdErrors: {
        aliasEvidenceId: null,
        legalEntityNameEvidenceId: null,
        previousEntityNameEvidenceId: null,
        legalStructureEvidenceId: null,
        entityTypeEvidenceId: null,
        entitySubTypeEvidenceId: null,
        businessStatusEvidenceId: null,
        usGseFlagEvidenceId: null,
        branchIndicatorEvidenceId: null
      },
      inputNameMapping: {},
      evidenceIdMapping: null,
      entitySubTypeListWatcher: true,
      inactivationReasonsListWatcher: true,
      isMandatorySubmit: false,
      isEntityTypeChangedConfirmed: false,
      isEntitySubTypeChangedConfirmed: false
    }
  },

  computed: {
    ...mapState('basicDetails', [
      'entityTypeList',
      'entitySubTypeList',
      'basicDetails',
      'enrichmentAnswerChoices',
      'legaStructures',
      'inactivationReasons',
      'bvdBasicDetails'
    ]),
    ...mapState('evidence', ['evidenceIdList']),
    ...mapGetters('evidence', ['getEvidenceFormStatus', 'getBtnLoadingStatus']),
    ...mapGetters('dmp', ['getEntityDetails']),
    isValidForm() {
      // Validate Legal Entity Name
      const isValidLegalEntityName =
        this.newBasicDetails.legalEntityName &&
        this.newBasicDetails.legalEntityName.length > 0 &&
        this.newBasicDetails.legalEntityName.length <= 500 &&
        isValidEnglishASCII(this.newBasicDetails.legalEntityName)

      // Validate Previous Entity Name
      const isValidPreviousEntityName = this.newBasicDetails.previousEntityName
        ? this.newBasicDetails.previousEntityName.length <= 4000 &&
          isValidEnglishASCII(this.newBasicDetails.previousEntityName)
        : true

      const isValidAlias = this.newBasicDetails.alias
        ? this.newBasicDetails.alias.length <= 4000 &&
          isValidEnglishASCII(this.newBasicDetails.alias)
        : true

      // Validate Entity Type and Entity Sub Type
      const isValidEntityType =
        this.newBasicDetails.entityTypeValue &&
        this.newBasicDetails.entityTypeValue.value !== null &&
        Number.isInteger(this.newBasicDetails.entityTypeValue.value)

      const isValidEntitySubType =
        this.newBasicDetails.entitySubTypeValue &&
        this.newBasicDetails.entitySubTypeValue.value !== null &&
        Number.isInteger(this.newBasicDetails.entitySubTypeValue.value)

      // Validate US GSE Flag
      const isValidUSGSEFlag =
        this.newBasicDetails.usGseFlag &&
        this.newBasicDetails.usGseFlag.value !== null &&
        Number.isInteger(this.newBasicDetails.usGseFlag.value)

      // Validate Business Status Inactivation Reason
      const isValidBusinessStatusInactivationReason = // TODO: mandatory or not, based on that do the changes.
        this.newBasicDetails.businessStatus &&
        this.newBasicDetails.businessStatus.value === false
          ? this.newBasicDetails.businessStatusInactiveReason &&
            this.newBasicDetails.businessStatusInactiveReason.value !== null &&
            Number.isInteger(
              this.newBasicDetails.businessStatusInactiveReason.value
            )
          : true

      return (
        isValidLegalEntityName &&
        isValidPreviousEntityName &&
        isValidEntityType &&
        isValidEntitySubType &&
        isValidUSGSEFlag &&
        isValidBusinessStatusInactivationReason &&
        isValidAlias
      )
    },

    isValidFields() {
      // Validate Legal Entity Name
      const isValidLegalEntityName = this.newBasicDetails.legalEntityName
        ? this.newBasicDetails.legalEntityName.length > 0 &&
          this.newBasicDetails.legalEntityName.length <= 500 &&
          isValidEnglishASCII(this.newBasicDetails.legalEntityName)
        : true

      // Validate Previous Entity Name
      const isValidPreviousEntityName = this.newBasicDetails.previousEntityName
        ? this.newBasicDetails.previousEntityName.length <= 4000 &&
          isValidEnglishASCII(this.newBasicDetails.previousEntityName)
        : true

      const isValidAlias = this.newBasicDetails.alias
        ? this.newBasicDetails.alias.length <= 4000 &&
          isValidEnglishASCII(this.newBasicDetails.alias)
        : true

      const data = {
        validFields: {
          isValidLegalEntityName,
          isValidPreviousEntityName,
          isValidAlias
        },
        areAllFieldsValid: Boolean(
          isValidLegalEntityName && isValidPreviousEntityName && isValidAlias
        )
      }
      return data
    },

    isBusinessInactiveReasonRequired() {
      return (
        this.newBasicDetails.businessStatus &&
        this.newBasicDetails.businessStatus.value === false
      )
    },

    showPreviousEntityNameEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const {
        isPreviousEntityNameChanged,
        isPreviousEntityNameEvidenceChanged
      } = changedFields

      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isPreviousEntityNameChanged || isPreviousEntityNameEvidenceChanged) &&
        this.evidenceIdErrors.previousEntityNameEvidenceId !== null
      )
    },

    showLegalEntityNameEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isLegalEntityNameChanged, isLegalEntityNameEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isLegalEntityNameChanged || isLegalEntityNameEvidenceChanged) &&
        this.evidenceIdErrors.legalEntityNameEvidenceId !== null
      )
    },

    showAliasEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isAliasChanged, isAliasEvidenceChanged } = changedFields

      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isAliasChanged || isAliasEvidenceChanged) &&
        this.evidenceIdErrors.aliasEvidenceId !== null
      )
    },

    getAliasEvidenceError() {
      return this.evidenceIdErrors.aliasEvidenceId
    },

    showLegalStructureEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isLegalStructureChanged, isLegalStructureEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isLegalStructureChanged || isLegalStructureEvidenceChanged) &&
        this.evidenceIdErrors.legalStructureEvidenceId !== null
      )
    },

    getLegalStructureEvidenceError() {
      return this.evidenceIdErrors.legalStructureEvidenceId
    },

    showBusinessStatusEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isBusinessStatusChanged, isBusinessStatusEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isBusinessStatusChanged || isBusinessStatusEvidenceChanged) &&
        this.evidenceIdErrors.businessStatusEvidenceId !== null
      )
    },

    getBusinessStatusEvidenceError() {
      return this.evidenceIdErrors.businessStatusEvidenceId
    },

    showBranchIndicatorEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isBranchIndicatorChanged, isBranchIndicatorEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isBranchIndicatorChanged || isBranchIndicatorEvidenceChanged) &&
        this.evidenceIdErrors.branchIndicatorEvidenceId !== null
      )
    },

    showEntityTypeEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isEntityTypeChanged, isEntityTypeEvidenceChanged } = changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isEntityTypeChanged || isEntityTypeEvidenceChanged) &&
        this.evidenceIdErrors.entityTypeEvidenceId !== null
      )
    },

    showEntitySubTypeEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isEntitySubTypeChanged, isEntitySubTypeEvidenceChanged } =
        changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isEntitySubTypeChanged || isEntitySubTypeEvidenceChanged) &&
        this.evidenceIdErrors.entitySubTypeEvidenceId !== null
      )
    },

    showUsGseFlagEvidenceError() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const { isUSGseFlagChanged, isUSGseFlagEvidenceChanged } = changedFields
      return (
        (this.submitted || this.isMandatorySubmit) &&
        (isUSGseFlagChanged || isUSGseFlagEvidenceChanged) &&
        this.evidenceIdErrors.usGseFlagEvidenceId !== null
      )
    },

    getBranchIndicatorEvidenceError() {
      return this.evidenceIdErrors.branchIndicatorEvidenceId
    }
  },

  watch: {
    basicDetails() {
      this.syncNewBasicDetailsWithBasicDetails()
    },

    newBasicDetails: {
      handler(newValue) {
        // watch newBasicDetails for the changes
        // assigned original evidence if the data is original i.e not changed
        const propertiesToCompare = [
          'legalEntityName',
          'alias',
          'previousEntityName',
          'businessStatus',
          'usGseFlag',
          'branchIndicator',
          'entityTypeId',
          'entitySubTypeId',
          'legalStructure'
        ]
        propertiesToCompare.forEach((property) => {
          if (
            property === 'entityTypeId' &&
            isEqual(
              this.newBasicDetails.entityTypeValue?.value?.toString(),
              this.basicDetails[property]?.toString()
            )
          ) {
            const evidenceIdPropertyName = 'entityTypeEvidenceId'
            const evdIdName = this.getKeyByValue(
              this.evidenceIdKeyMapping,
              evidenceIdPropertyName
            )

            // remove added evidence then re-add if already present
            this.removeEvidence(evdIdName[0])
            if (this.basicDetails.entityTypeEvidence) {
              this.updateEvidenceIdList(evdIdName[0])
              this.updateInputNameMapping(
                evdIdName[0],
                this.basicDetails.entityTypeEvidence
              )
            }
          } else if (
            property === 'entitySubTypeId' &&
            isEqual(
              this.newBasicDetails.entitySubTypeValue?.value.toString(),
              this.basicDetails[property]?.toString()
            )
          ) {
            const evidenceIdPropertyName = 'entitySubTypeEvidenceId'
            const evdIdName = this.getKeyByValue(
              this.evidenceIdKeyMapping,
              evidenceIdPropertyName
            )

            // remove added evidence then re-add if already present
            this.removeEvidence(evdIdName[0])
            if (this.basicDetails.entitySubTypeEvidence) {
              this.updateEvidenceIdList(evdIdName[0])
              this.updateInputNameMapping(
                evdIdName[0],
                this.basicDetails.entitySubTypeEvidence
              )
            }
          } else if (
            isEqual(
              this.newBasicDetails[property],
              this.basicDetails[property]
            ) ||
            (typeof this.newBasicDetails[property] === 'object' &&
              this.newBasicDetails[property] !== null &&
              this.newBasicDetails[property] !== undefined &&
              isEqual(
                this.newBasicDetails[property]?.value.toString(),
                this.basicDetails[property]?.toString()
              ))
          ) {
            const evidenceIdPropertyName = `${property}EvidenceId`
            const evdIdName = this.getKeyByValue(
              this.evidenceIdKeyMapping,
              evidenceIdPropertyName
            )

            // remove added evidence then re-add if already present
            this.removeEvidence(evdIdName[0])
            if (this.basicDetails[`${property}Evidence`]) {
              this.updateEvidenceIdList(evdIdName[0])
              this.updateInputNameMapping(
                evdIdName[0],
                this.basicDetails[`${property}Evidence`]
              )
            }
          }
        })
      },
      deep: true
    },

    entitySubTypeList() {
      // Un-watch after the first trigger
      if (
        this.entitySubTypeListWatcher ||
        !this.isEntitySubTypeChangedConfirmed
      ) {
        if (!this.isEntitySubTypeChangedConfirmed) {
          // removing the new evidence so that original evidence is attached
          this.removeEvidence(basicDetailEvidenceId.ENTITY_SUB_TYPE)
          this.newBasicDetails.entitySubTypeEvidenceId = null
        }
        this.newBasicDetails.entitySubTypeValue =
          this.entitySubTypeList.find((entitySubType) => {
            return entitySubType.value === this.basicDetails.entitySubTypeId
          }) ?? null
        this.entitySubTypeListWatcher = false
        this.isEntitySubTypeChangedConfirmed = false
      }
    },

    inactivationReasons() {
      this.newBasicDetails.businessStatusInactiveReason =
        this.inactivationReasons.find(
          (reason) =>
            reason.value === this.basicDetails.businessInactivationReasonId
        ) ?? null
    },
    bvdBasicDetails: {
      handler(newVal) {
        this.syncBVDData(newVal)
      },
      deep: true
    },

    evidenceIdList: {
      // check for evidence from the vuex store, in case if any is present, then remove the error
      handler(newEvidenceList) {
        const newEvidenceListMapping = newEvidenceList.map(
          (item) => this.evidenceIdKeyMapping[item]
        )
        for (const evidenceKey in this.evidenceIdErrors) {
          // Check if the evidenceKey is present in the newEvidenceList
          if (newEvidenceListMapping.includes(evidenceKey)) {
            // Set the error for this evidence to null
            this.evidenceIdErrors[evidenceKey] = null
          }
        }
      }
    }
  },

  async mounted() {
    this.getFormDetails()
  },

  methods: {
    ...mapActions('basicDetails', [
      'getEntityTypeList',
      'getEntitySubTypeList',
      'updateEntitySubTypeList',
      'getBasicDetails',
      'getBasicEnrichmentAnswerChoices',
      'getLegalStructures',
      'getInactivationReason',
      'updateBasicDetails',
      'updateInactiveReasonList'
    ]),
    ...mapActions('evidence', [
      'uploadEvidence',
      'updateEvidenceIdList',
      'resetEvidenceStore',
      'removeEvidence'
    ]),
    ...mapActions('industry', ['deleteIndustryDetail']),
    ...mapActions('assignedEntities', [
      'checkAndUpdateTodoStatus',
      'getEntityDetailsById'
    ]),
    async getFormDetails() {
      try {
        this.loading = true
        this.resetEvidenceStore()
        const { sgaId } = this.getEntityDetails
        this.sgaId = sgaId
        // Clear the state when navigating to the BasicDetails page
        await this.updateInactiveReasonList([])
        await this.updateEntitySubTypeList([])
        await this.getEntityTypeList()
        await this.getBasicEnrichmentAnswerChoices()
        await this.getLegalStructures()
        await this.getBasicDetails(this.sgaId)
      } finally {
        this.loading = false
      }
    },
    getFormData() {
      return this.newBasicDetails
    },
    updateField(key, value) {
      this.updateBasicDetails({ key, value })
    },
    saveChanges(updatedData) {
      Object.assign(this.newBasicDetails, updatedData)
      // Perform any other necessary actions (e.g., API calls)
    },

    syncNewBasicDetailsWithBasicDetails() {
      this.newBasicDetails.alias = this.basicDetails
        ? this.basicDetails.alias
        : ''
      this.newBasicDetails.previousEntityName = this.basicDetails
        ? this.basicDetails.previousEntityName
        : ''
      this.newBasicDetails.branchIndicator =
        this.basicDetails && this.basicDetails.branchIndicator
          ? this.enrichmentAnswerChoices.find(
              (reason) => reason.value === this.basicDetails.branchIndicator
            )
          : null
      this.newBasicDetails.legalEntityName = this.basicDetails
        ? this.basicDetails.legalEntityName
        : ''

      this.newBasicDetails.businessStatus =
        this.basicDetails && this.basicDetails.businessStatus !== null
          ? this.businessStatusOptions.find(
              (reason) => reason.value === this.basicDetails.businessStatus
            )
          : null

      this.newBasicDetails.businessStatusInactiveReason =
        this.basicDetails &&
        this.basicDetails.businessInactivationReasonId !== null
          ? this.inactivationReasons.find(
              (reason) =>
                reason.value === this.basicDetails.businessInactivationReasonId
            )
          : null

      this.newBasicDetails.usGseFlag =
        this.basicDetails && this.basicDetails.usGseFlag
          ? this.enrichmentAnswerChoices.find(
              (reason) => reason.value === this.basicDetails.usGseFlag
            )
          : null

      const legalStructure =
        this.basicDetails && this.basicDetails.legalStructure
          ? this.basicDetails.legalStructure
          : null
      this.newBasicDetails.legalStructure = legalStructure
        ? this.legaStructures.find(
            (reason) => reason.value === legalStructure // Not comparing the types as the value and datatype is string in the db, but the legal structure value is number in the legal_structure table
          )
        : null
      this.newBasicDetails.entityTypeValue =
        this.basicDetails && this.basicDetails.entityTypeId
          ? this.entityTypeList.find(
              (entityType) =>
                entityType.value === this.basicDetails.entityTypeId
            )
          : null

      this.newBasicDetails.aliasEvidenceId = this.basicDetails
        ? this.basicDetails.aliasEvidence
        : null
      this.newBasicDetails.branchIndicatorEvidenceId = this.basicDetails
        ? this.basicDetails.branchIndicatorEvidence
        : null
      this.newBasicDetails.businessStatusEvidenceId = this.basicDetails
        ? this.basicDetails.businessStatusEvidence
        : null
      this.newBasicDetails.entitySubTypeEvidenceId = this.basicDetails
        ? this.basicDetails.entitySubTypeEvidence
        : null
      this.newBasicDetails.entityTypeEvidenceId = this.basicDetails
        ? this.basicDetails.entityTypeEvidence
        : null
      this.newBasicDetails.legalEntityNameEvidenceId = this.basicDetails
        ? this.basicDetails.legalEntityNameEvidence
        : null
      this.newBasicDetails.legalStructureEvidenceId = this.basicDetails
        ? this.basicDetails.legalStructureEvidence
        : null
      this.newBasicDetails.previousEntityNameEvidenceId = this.basicDetails
        ? this.basicDetails.previousEntityNameEvidence
        : null
      this.newBasicDetails.usGseFlagEvidenceId = this.basicDetails
        ? this.basicDetails.usGseFlagEvidence
        : null
      if (this.newBasicDetails.aliasEvidenceId) {
        this.updateEvidenceIdList(basicDetailEvidenceId.ALIAS)
        this.updateInputNameMapping(
          basicDetailEvidenceId.ALIAS,
          this.newBasicDetails.aliasEvidenceId
        )
      }

      if (this.newBasicDetails.legalEntityNameEvidenceId) {
        this.updateEvidenceIdList(basicDetailEvidenceId.LEGAL_ENTITY_NAME)
        this.updateInputNameMapping(
          basicDetailEvidenceId.LEGAL_ENTITY_NAME,
          this.newBasicDetails.legalEntityNameEvidenceId
        )
      }

      if (this.newBasicDetails.previousEntityNameEvidenceId) {
        this.updateEvidenceIdList(basicDetailEvidenceId.PREVIOUS_ENTITY_NAME)
        this.updateInputNameMapping(
          basicDetailEvidenceId.PREVIOUS_ENTITY_NAME,
          this.newBasicDetails.previousEntityNameEvidenceId
        )
      }
      if (this.newBasicDetails.legalStructureEvidenceId) {
        this.updateEvidenceIdList(basicDetailEvidenceId.LEGAL_STRUCTURE)
        this.updateInputNameMapping(
          basicDetailEvidenceId.LEGAL_STRUCTURE,
          this.newBasicDetails.legalStructureEvidenceId
        )
      }

      if (this.newBasicDetails.entityTypeEvidenceId) {
        this.updateEvidenceIdList(basicDetailEvidenceId.ENTITY_TYPE)
        this.updateInputNameMapping(
          basicDetailEvidenceId.ENTITY_TYPE,
          this.newBasicDetails.entityTypeEvidenceId
        )
      }

      if (this.newBasicDetails.entitySubTypeEvidenceId) {
        this.updateEvidenceIdList(basicDetailEvidenceId.ENTITY_SUB_TYPE)
        this.updateInputNameMapping(
          basicDetailEvidenceId.ENTITY_SUB_TYPE,
          this.newBasicDetails.entitySubTypeEvidenceId
        )
      }

      if (this.newBasicDetails.businessStatusEvidenceId) {
        this.updateEvidenceIdList(basicDetailEvidenceId.BUSINESS_STATUS)
        this.updateInputNameMapping(
          basicDetailEvidenceId.BUSINESS_STATUS,
          this.newBasicDetails.businessStatusEvidenceId
        )
      }

      if (this.newBasicDetails.usGseFlagEvidenceId) {
        this.updateEvidenceIdList(basicDetailEvidenceId.US_GSE_FLAG)
        this.updateInputNameMapping(
          basicDetailEvidenceId.US_GSE_FLAG,
          this.newBasicDetails.usGseFlagEvidenceId
        )
      }

      if (this.newBasicDetails.branchIndicatorEvidenceId) {
        this.updateEvidenceIdList(basicDetailEvidenceId.BRANCH_INDICATOR)
        this.updateInputNameMapping(
          basicDetailEvidenceId.BRANCH_INDICATOR,
          this.newBasicDetails.branchIndicatorEvidenceId
        )
      }
    },
    syncBVDData(newVal) {
      if (Object.keys(newVal).length > 0) {
        this.newBasicDetails = {
          ...this.newBasicDetails,
          ...newVal
        }

        // Force update evidence status
        this.$nextTick(() => {
          this.forceUpdateEvidenceStatus()
        })
      }
    },
    forceUpdateEvidenceStatus() {
      // this method is used for syncing the evidence issue for the fields updated through bvd form
      const propertiesToCompare = [
        'legalEntityName',
        'alias',
        'previousEntityName',
        'businessStatus'
      ]

      propertiesToCompare.forEach((property) => {
        if (
          isEqual(
            this.newBasicDetails[property],
            this.basicDetails[property]
          ) ||
          (typeof this.newBasicDetails[property] === 'object' &&
            this.newBasicDetails[property] !== null &&
            this.newBasicDetails[property] !== undefined &&
            isEqual(
              this.newBasicDetails[property]?.value.toString(),
              this.basicDetails[property]?.toString()
            ))
        ) {
          const evidenceIdPropertyName = `${property}EvidenceId`
          const evdIdName = this.getKeyByValue(
            this.evidenceIdKeyMapping,
            evidenceIdPropertyName
          )

          // remove added evidence then re-add if already present
          this.removeEvidence(evdIdName[0])
          if (this.basicDetails[`${property}Evidence`]) {
            this.updateEvidenceIdList(evdIdName[0])
            this.updateInputNameMapping(
              evdIdName[0],
              this.basicDetails[`${property}Evidence`]
            )
          }
        }
      })
    },

    goBack() {
      // Check if there are unsaved changes
      const { hasUnsavedChanges } = this.hasUnsavedChangesFunc()
      if (hasUnsavedChanges) {
        this.confirmationDialogData.visible = true
        this.confirmationDialogData.title = 'Discard changes?'
        this.confirmationDialogData.text =
          'Would you like to discard the changes & go back <br>to Assigned Entities screen?'
        this.confirmationDialogData.triggerFunction = this.resetFormData // this is the function that will be triggered on the confirm button
      } else {
        this.navigateBack()
      }
    },

    clearEvidenceIdErrors() {
      for (const evidenceKey in this.evidenceIdErrors) {
        this.evidenceIdErrors[evidenceKey] = null
      }
    },

    hasUnsavedChangesFunc() {
      const isAliasChanged =
        this.newBasicDetails.alias !==
        (this.basicDetails && this.basicDetails.alias !== undefined
          ? this.basicDetails.alias
          : '')

      const isLegalEntityNameChanged =
        this.newBasicDetails.legalEntityName !==
        (this.basicDetails && this.basicDetails.legalEntityName !== undefined
          ? this.basicDetails.legalEntityName
          : '')

      const isPreviousEntityNameChanged =
        this.newBasicDetails.previousEntityName !==
        (this.basicDetails && this.basicDetails.previousEntityName !== undefined
          ? this.basicDetails.previousEntityName
          : '')

      const isLegalStructureChanged = this.newBasicDetails.legalStructure
        ? !(
            this.newBasicDetails.legalStructure.value ==
            (this.basicDetails && this.basicDetails.legalStructure !== undefined
              ? this.basicDetails.legalStructure
              : null)
          )
        : !(
            this.newBasicDetails.legalStructure ==
            (this.basicDetails && this.basicDetails.legalStructure !== undefined
              ? this.basicDetails.legalStructure
              : null)
          )

      const isEntityTypeChanged = this.newBasicDetails.entityTypeValue
        ? this.newBasicDetails.entityTypeValue.value !==
          (this.basicDetails && this.basicDetails.entityTypeId !== undefined
            ? this.basicDetails.entityTypeId
            : null)
        : this.newBasicDetails.entityTypeValue !==
          (this.basicDetails && this.basicDetails.entityTypeId !== undefined
            ? this.basicDetails.entityTypeId
            : null)

      const isEntitySubTypeChanged = this.newBasicDetails.entitySubTypeValue
        ? this.newBasicDetails.entitySubTypeValue.value !==
          (this.basicDetails && this.basicDetails.entitySubTypeId !== undefined
            ? this.basicDetails.entitySubTypeId
            : null)
        : this.newBasicDetails.entitySubTypeValue !==
          (this.basicDetails && this.basicDetails.entitySubTypeId !== undefined
            ? this.basicDetails.entitySubTypeId
            : null)

      const isUSGseFlagChanged = this.newBasicDetails.usGseFlag
        ? this.newBasicDetails.usGseFlag.value !==
          (this.basicDetails && this.basicDetails.usGseFlag !== undefined
            ? this.basicDetails.usGseFlag
            : null)
        : this.newBasicDetails.usGseFlag !==
          (this.basicDetails && this.basicDetails.usGseFlag !== undefined
            ? this.basicDetails.usGseFlag
            : null)

      const isBranchIndicatorChanged = this.newBasicDetails.branchIndicator
        ? this.newBasicDetails.branchIndicator.value !==
          (this.basicDetails && this.basicDetails.branchIndicator !== undefined
            ? this.basicDetails.branchIndicator
            : null)
        : this.newBasicDetails.branchIndicator !==
          (this.basicDetails && this.basicDetails.branchIndicator !== undefined
            ? this.basicDetails.branchIndicator
            : null)

      const isBusinessStatusInactiveReasonChanged = this.newBasicDetails
        .businessStatusInactiveReason
        ? this.newBasicDetails.businessStatusInactiveReason.value !==
          (this.basicDetails &&
          this.basicDetails.businessInactivationReasonId !== undefined
            ? this.basicDetails.businessInactivationReasonId
            : null)
        : (this.syncNewBasicDetailsWithBasicDetails
            .businessStatusInactiveReason === undefined
            ? null
            : this.newBasicDetails.businessStatusInactiveReason) !==
          (this.basicDetails &&
          this.basicDetails.businessInactivationReasonId !== undefined
            ? this.basicDetails.businessInactivationReasonId
            : null)

      const isBusinessStatusChanged = this.newBasicDetails.businessStatus
        ? this.newBasicDetails.businessStatus.value !==
          (this.basicDetails && this.basicDetails.businessStatus !== undefined
            ? this.basicDetails.businessStatus
            : null)
        : this.newBasicDetails.businessStatus !==
          (this.basicDetails && this.basicDetails.businessStatus !== undefined
            ? this.basicDetails.businessStatus
            : null)

      const isAliasEvidenceChanged =
        this.basicDetails && this.basicDetails.aliasEvidence
          ? !this.isEvidencePresentInInputMapping(basicDetailEvidenceId.ALIAS)
          : this.isEvidencePresentInInputMapping(basicDetailEvidenceId.ALIAS)

      const isLegalEntityNameEvidenceChanged =
        this.basicDetails && this.basicDetails.legalEntityNameEvidence
          ? !this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.LEGAL_ENTITY_NAME
            )
          : this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.LEGAL_ENTITY_NAME
            )

      const isPreviousEntityNameEvidenceChanged =
        this.basicDetails && this.basicDetails.previousEntityNameEvidence
          ? !this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.PREVIOUS_ENTITY_NAME
            )
          : this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.PREVIOUS_ENTITY_NAME
            )

      const isLegalStructureEvidenceChanged =
        this.basicDetails && this.basicDetails.legalStructureEvidence
          ? !this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.LEGAL_STRUCTURE
            )
          : this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.LEGAL_STRUCTURE
            )

      const isEntityTypeEvidenceChanged =
        this.basicDetails && this.basicDetails.entityTypeEvidence
          ? !this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.ENTITY_TYPE
            )
          : this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.ENTITY_TYPE
            )

      const isEntitySubTypeEvidenceChanged =
        this.basicDetails && this.basicDetails.entitySubTypeEvidence
          ? !this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.ENTITY_SUB_TYPE
            )
          : this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.ENTITY_SUB_TYPE
            )

      const isBusinessStatusEvidenceChanged =
        this.basicDetails && this.basicDetails.businessStatusEvidence
          ? !this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.BUSINESS_STATUS
            )
          : this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.BUSINESS_STATUS
            )

      const isUSGseFlagEvidenceChanged =
        this.basicDetails && this.basicDetails.usGseFlagEvidence
          ? !this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.US_GSE_FLAG
            )
          : this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.US_GSE_FLAG
            )

      const isBranchIndicatorEvidenceChanged =
        this.basicDetails && this.basicDetails.branchIndicatorEvidence
          ? !this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.BRANCH_INDICATOR
            )
          : this.isEvidencePresentInInputMapping(
              basicDetailEvidenceId.BRANCH_INDICATOR
            )

      const changedFields = {
        isAliasChanged,
        isLegalEntityNameChanged,
        isPreviousEntityNameChanged,
        isLegalStructureChanged,
        isEntityTypeChanged,
        isEntitySubTypeChanged,
        isUSGseFlagChanged,
        isBranchIndicatorChanged,
        isBusinessStatusInactiveReasonChanged,
        isBusinessStatusChanged,
        isAliasEvidenceChanged,
        isLegalEntityNameEvidenceChanged,
        isPreviousEntityNameEvidenceChanged,
        isLegalStructureEvidenceChanged,
        isEntityTypeEvidenceChanged,
        isEntitySubTypeEvidenceChanged,
        isBusinessStatusEvidenceChanged,
        isUSGseFlagEvidenceChanged,
        isBranchIndicatorEvidenceChanged
      }

      // Compare each form field with its initial value
      if (
        !isAliasChanged &&
        !isLegalEntityNameChanged &&
        !isPreviousEntityNameChanged &&
        !isLegalStructureChanged &&
        !isEntityTypeChanged &&
        !isEntitySubTypeChanged &&
        !isUSGseFlagChanged &&
        !isBranchIndicatorChanged &&
        !isBusinessStatusInactiveReasonChanged &&
        !isBusinessStatusChanged &&
        !isAliasEvidenceChanged &&
        !isLegalEntityNameEvidenceChanged &&
        !isPreviousEntityNameEvidenceChanged &&
        !isLegalStructureEvidenceChanged &&
        !isEntityTypeEvidenceChanged &&
        !isEntitySubTypeEvidenceChanged &&
        !isBusinessStatusEvidenceChanged &&
        !isUSGseFlagEvidenceChanged &&
        !isBranchIndicatorEvidenceChanged
      ) {
        return {
          hasUnsavedChanges: false,
          changedFields
        }
      } else {
        return {
          hasUnsavedChanges: true,
          changedFields
        }
      }
    },

    closeDialog() {
      // gets executed when user cancel the popup
      if (!this.isEntityTypeChangedConfirmed) {
        // removing the new evidence so that original evidence is attached
        this.removeEvidence(basicDetailEvidenceId.ENTITY_TYPE)
        this.newBasicDetails.entityTypeEvidenceId = null

        this.newBasicDetails.entityTypeValue =
          this.entityTypeList.find((entityType) => {
            return entityType.value === this.basicDetails.entityTypeId
          }) ?? null

        // populate entity subtype list
        if (this.newBasicDetails.entityTypeValue) {
          this.getEntitySubTypeList(this.newBasicDetails.entityTypeValue.value)
        } else {
          this.updateEntitySubTypeList([])
        }

        this.isEntityTypeChangedConfirmed = false
      }

      this.confirmationDialogData = {
        ...this.confirmationDialogData,
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      }
    },

    resetFormData() {
      // reset form data to its initial state
      this.syncNewBasicDetailsWithBasicDetails()
      this.navigateBack()
    },

    navigateBack() {
      // Redirected to DMP Main Component for Role based redirection
      this.$router.push({
        name: 'data-management-platform'
      })
    },

    async saveData() {
      if (this.isMandatorySubmit) {
        // set this flag to false, so as to bypass the validations
        this.isMandatorySubmit = false
      }
      this.submitted = true
      try {
        this.loading = true
        const { areAllFieldsValid } = this.isValidFields
        const isValidEvidences = await this.checkForValidEvidences()
        if (areAllFieldsValid && isValidEvidences) {
          this.clearEvidenceIdErrors()
          const entityTypeChanged = !isEqual(
            this.newBasicDetails.entityTypeValue?.value?.toString(),
            this.basicDetails.entityTypeId?.toString()
          )
          const entitySubTypeChanged = !isEqual(
            this.newBasicDetails.entitySubTypeValue?.value.toString(),
            this.basicDetails.entitySubTypeId?.toString()
          )

          if (
            (this.basicDetails.entityTypeId && entityTypeChanged) ||
            (this.basicDetails.entitySubTypeId && entitySubTypeChanged)
          ) {
            this.confirmationDialogData.visible = true
            this.confirmationDialogData.title = 'Do you still want to continue?'
            this.confirmationDialogData.text =
              'Entity type or Entity sub-type is changed so Industry details will be blank.'
            this.confirmationDialogData.triggerFunction = async () => {
              this.isEntityTypeChangedConfirmed = true
              this.isEntitySubTypeChangedConfirmed = true
              const payloadData = this.createPayloadForUpdate()
              const payload = { sgaId: this.sgaId, payloadData: payloadData }
              this.loading = true
              // Check entity status and update it to In Progress, if it's Todo
              await this.checkAndUpdateTodoStatus()
              await this.updateBasicDetails(payload)

              await this.deleteIndustryDetail(this.sgaId)
              this.loading = false
            }
          } else {
            const payloadData = this.createPayloadForUpdate()
            const payload = { sgaId: this.sgaId, payloadData: payloadData }
            this.loading = true
            // Check entity status and update it to In Progress, if it's Todo
            await this.checkAndUpdateTodoStatus()
            this.updateBasicDetails(payload)
            this.loading = false
          }
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },

    async saveAndNext() {
      this.loading = true
      this.isMandatorySubmit = true
      this.submitted = true
      const isValidFormData = this.isValidForm
      const isValidEvidences = await this.checkForValidEvidences()
      if (isValidFormData && isValidEvidences) {
        this.clearEvidenceIdErrors()

        const entityTypeChanged = !isEqual(
          this.newBasicDetails.entityTypeValue?.value?.toString(),
          this.basicDetails.entityTypeId?.toString()
        )
        const entitySubTypeChanged = !isEqual(
          this.newBasicDetails.entitySubTypeValue?.value.toString(),
          this.basicDetails.entitySubTypeId?.toString()
        )

        if (
          (this.basicDetails.entityTypeId && entityTypeChanged) ||
          (this.basicDetails.entitySubTypeId && entitySubTypeChanged)
        ) {
          this.confirmationDialogData.visible = true
          this.confirmationDialogData.title = 'Do you still want to continue?'
          this.confirmationDialogData.text =
            'Entity type or Entity sub-type is changed so Industry details will be blank.'
          this.confirmationDialogData.triggerFunction = async () => {
            this.isEntityTypeChangedConfirmed = true
            this.isEntitySubTypeChangedConfirmed = true

            this.loading = true
            const payloadData = this.createPayloadForUpdate()
            try {
              const payload = { sgaId: this.sgaId, payloadData: payloadData }
              // Check entity status and update it to In Progress, if it's Todo
              await this.checkAndUpdateTodoStatus()
              const responseStatus = await this.updateBasicDetails(payload)
              if (responseStatus && responseStatus === 200) {
                this.updateStepper(this.activeStepIndexName.addressDetails)
              }
            } catch (error) {
              this.loading = false // Stop the loader when the request fails
            }
            await this.deleteIndustryDetail(this.sgaId)
            this.loading = false
          }
        } else {
          this.loading = true
          const payloadData = this.createPayloadForUpdate()
          try {
            const payload = { sgaId: this.sgaId, payloadData: payloadData }
            // Check entity status and update it to In Progress, if it's Todo
            await this.checkAndUpdateTodoStatus()
            const responseStatus = await this.updateBasicDetails(payload)
            if (responseStatus && responseStatus === 200) {
              this.updateStepper(this.activeStepIndexName.addressDetails)
            }
          } catch (error) {
            this.loading = false // Stop the loader when the request fails
          }
          this.loading = false
        }
        this.loading = false
      }
      this.loading = false // Stop the loader when the request is successful
      return isValidFormData
    },

    createPayloadForUpdate() {
      return {
        legalEntityName: this.newBasicDetails.legalEntityName,
        previousEntityName: this.newBasicDetails.previousEntityName,
        legalStructure: this.newBasicDetails.legalStructure
          ? this.newBasicDetails.legalStructure.value
          : null,
        alias: this.newBasicDetails.alias,
        entityTypeId: this.newBasicDetails.entityTypeValue
          ? this.newBasicDetails.entityTypeValue.value
          : null,
        entitySubTypeId: this.newBasicDetails.entitySubTypeValue
          ? this.newBasicDetails.entitySubTypeValue.value
          : null,
        businessStatus: this.newBasicDetails.businessStatus
          ? this.newBasicDetails.businessStatus.value
          : null,
        businessInactivationReasonId: this.newBasicDetails
          .businessStatusInactiveReason
          ? this.newBasicDetails.businessStatusInactiveReason.value
          : null,
        businessInactivationReason: this.newBasicDetails
          .businessStatusInactiveReason
          ? this.newBasicDetails.businessStatusInactiveReason.text.split('-')[1]
          : null,
        usGseFlag: this.newBasicDetails.usGseFlag
          ? this.newBasicDetails.usGseFlag.value
          : null,
        branchIndicator: this.newBasicDetails.branchIndicator
          ? this.newBasicDetails.branchIndicator.value
          : null,
        aliasEvidenceId: this.newBasicDetails.aliasEvidenceId,
        legalEntityNameEvidenceId:
          this.newBasicDetails.legalEntityNameEvidenceId,
        previousEntityNameEvidenceId:
          this.newBasicDetails.previousEntityNameEvidenceId,
        legalStructureEvidenceId: this.newBasicDetails.legalStructureEvidenceId,
        entityTypeEvidenceId: this.newBasicDetails.entityTypeEvidenceId,
        entitySubTypeEvidenceId: this.newBasicDetails.entitySubTypeEvidenceId,
        businessStatusEvidenceId: this.newBasicDetails.businessStatusEvidenceId,
        usGseFlagEvidenceId: this.newBasicDetails.usGseFlagEvidenceId,
        branchIndicatorEvidenceId:
          this.newBasicDetails.branchIndicatorEvidenceId
      }
    },

    async checkForValidEvidences() {
      this.evidenceIdMappingResponse = await this.uploadEvidence()
      this.updateEvidenceIdMapping(this.evidenceIdMappingResponse)
      const isValidEvidences = this.checkForEvidenceErrors()
      return isValidEvidences
    },

    updateEvidenceIdMapping(evidenceIdMappingResponse) {
      const evidenceIdMapping = {}
      if (
        evidenceIdMappingResponse &&
        Array.isArray(evidenceIdMappingResponse)
      ) {
        evidenceIdMappingResponse.forEach((item) => {
          if (item.evidenceIds && item.evidenceIds.length > 0) {
            const evidenceId = item ? Number(item.evidenceIds[0].id) : null
            evidenceIdMapping[item.attributeName] = evidenceId
          }
        })
      }
      this.newBasicDetails.aliasEvidenceId =
        this.isEvidencePresentInInputMapping(basicDetailEvidenceId.ALIAS)
          ? evidenceIdMapping[basicDetailEvidenceId.ALIAS] ||
            this.basicDetails.aliasEvidence
          : null

      this.newBasicDetails.legalEntityNameEvidenceId =
        this.isEvidencePresentInInputMapping(
          basicDetailEvidenceId.LEGAL_ENTITY_NAME
        )
          ? evidenceIdMapping[basicDetailEvidenceId.LEGAL_ENTITY_NAME] ||
            this.basicDetails.legalEntityNameEvidence
          : null

      this.newBasicDetails.previousEntityNameEvidenceId =
        this.isEvidencePresentInInputMapping(
          basicDetailEvidenceId.PREVIOUS_ENTITY_NAME
        )
          ? evidenceIdMapping[basicDetailEvidenceId.PREVIOUS_ENTITY_NAME] ||
            this.basicDetails.previousEntityNameEvidence
          : null

      this.newBasicDetails.legalStructureEvidenceId =
        this.isEvidencePresentInInputMapping(
          basicDetailEvidenceId.LEGAL_STRUCTURE
        )
          ? evidenceIdMapping[basicDetailEvidenceId.LEGAL_STRUCTURE] ||
            this.basicDetails.legalStructureEvidence
          : null

      this.newBasicDetails.entityTypeEvidenceId =
        this.isEvidencePresentInInputMapping(basicDetailEvidenceId.ENTITY_TYPE)
          ? evidenceIdMapping[basicDetailEvidenceId.ENTITY_TYPE] ||
            this.basicDetails.entityTypeEvidence
          : null

      this.newBasicDetails.entitySubTypeEvidenceId =
        this.isEvidencePresentInInputMapping(
          basicDetailEvidenceId.ENTITY_SUB_TYPE
        )
          ? evidenceIdMapping[basicDetailEvidenceId.ENTITY_SUB_TYPE] ||
            this.basicDetails.entitySubTypeEvidence
          : null

      this.newBasicDetails.businessStatusEvidenceId =
        this.isEvidencePresentInInputMapping(
          basicDetailEvidenceId.BUSINESS_STATUS
        )
          ? evidenceIdMapping[basicDetailEvidenceId.BUSINESS_STATUS] ||
            this.basicDetails.businessStatusEvidence
          : null

      this.newBasicDetails.usGseFlagEvidenceId =
        this.isEvidencePresentInInputMapping(basicDetailEvidenceId.US_GSE_FLAG)
          ? evidenceIdMapping[basicDetailEvidenceId.US_GSE_FLAG] ??
            this.basicDetails.usGseFlagEvidence
          : null

      this.newBasicDetails.branchIndicatorEvidenceId =
        this.isEvidencePresentInInputMapping(
          basicDetailEvidenceId.BRANCH_INDICATOR
        )
          ? evidenceIdMapping[basicDetailEvidenceId.BRANCH_INDICATOR] ||
            this.basicDetails.branchIndicatorEvidence
          : null
    },

    isEvidencePresentInInputMapping(inputName) {
      return this.evidenceIdList.includes(inputName)
    },

    checkForEvidenceErrors() {
      const { changedFields } = this.hasUnsavedChangesFunc()
      const {
        isAliasChanged,
        isLegalEntityNameChanged,
        isPreviousEntityNameChanged,
        isLegalStructureChanged,
        isEntityTypeChanged,
        isEntitySubTypeChanged,
        isUSGseFlagChanged,
        isBranchIndicatorChanged,
        isBusinessStatusChanged,
        isAliasEvidenceChanged,
        isLegalEntityNameEvidenceChanged,
        isPreviousEntityNameEvidenceChanged,
        isLegalStructureEvidenceChanged,
        isEntityTypeEvidenceChanged,
        isEntitySubTypeEvidenceChanged,
        isBusinessStatusEvidenceChanged,
        isUSGseFlagEvidenceChanged,
        isBranchIndicatorEvidenceChanged
      } = changedFields

      let isValidAliasEvidence = true
      let isValidLegalEntityNameEvidence = true
      let isValidPreviousEntityNameEvidence = true
      let isValidLegalStructureEvidence = true
      let isValidEntityTypeEvidence = true
      let isValidEntitySubTypeEvidence = true
      let isValidUSGseFlagEvidence = true
      let isValidBranchIndicatorEvidence = true
      let isValidBusinessStatusEvidence = true

      if (isAliasChanged || isAliasEvidenceChanged) {
        isValidAliasEvidence = this.validateEvidenceField(
          this.newBasicDetails,
          'aliasEvidenceId',
          'Alias'
        )
      }
      if (isLegalEntityNameChanged || isLegalEntityNameEvidenceChanged) {
        isValidLegalEntityNameEvidence = this.validateEvidenceField(
          this.newBasicDetails,
          'legalEntityNameEvidenceId',
          'Legal Entity Name'
        )
      }
      if (isPreviousEntityNameChanged || isPreviousEntityNameEvidenceChanged) {
        isValidPreviousEntityNameEvidence = this.validateEvidenceField(
          this.newBasicDetails,
          'previousEntityNameEvidenceId',
          'Previous Entity Name'
        )
      }
      if (isLegalStructureChanged || isLegalStructureEvidenceChanged) {
        isValidLegalStructureEvidence = this.validateEvidenceField(
          this.newBasicDetails,
          'legalStructureEvidenceId',
          'Legal Structure'
        )
      }
      if (isEntityTypeChanged || isEntityTypeEvidenceChanged) {
        isValidEntityTypeEvidence = this.validateEvidenceField(
          this.newBasicDetails,
          'entityTypeEvidenceId',
          'Entity Type'
        )
      }
      if (isEntitySubTypeChanged || isEntitySubTypeEvidenceChanged) {
        isValidEntitySubTypeEvidence = this.validateEvidenceField(
          this.newBasicDetails,
          'entitySubTypeEvidenceId',
          'Entity Sub Type'
        )
      }
      if (isUSGseFlagChanged || isUSGseFlagEvidenceChanged) {
        isValidUSGseFlagEvidence = this.validateEvidenceField(
          this.newBasicDetails,
          'usGseFlagEvidenceId',
          'US GSE Flag'
        )
      }
      if (isBranchIndicatorChanged || isBranchIndicatorEvidenceChanged) {
        isValidBranchIndicatorEvidence = this.validateEvidenceField(
          this.newBasicDetails,
          'branchIndicatorEvidenceId',
          'Branch Indicator'
        )
      }

      if (isBusinessStatusChanged || isBusinessStatusEvidenceChanged) {
        isValidBusinessStatusEvidence = this.validateEvidenceField(
          this.newBasicDetails,
          'businessStatusEvidenceId',
          'Business Status'
        )
      }

      return (
        isValidAliasEvidence &&
        isValidLegalEntityNameEvidence &&
        isValidPreviousEntityNameEvidence &&
        isValidLegalStructureEvidence &&
        isValidEntityTypeEvidence &&
        isValidEntitySubTypeEvidence &&
        isValidUSGseFlagEvidence &&
        isValidBranchIndicatorEvidence &&
        isValidBusinessStatusEvidence
      )
    },

    validateEvidenceField(payloadData, fieldName, errorMessage) {
      const fieldErrorMessage = `Please upload evidence for ${errorMessage}`
      if (!payloadData[fieldName]) {
        this.evidenceIdErrors[fieldName] = fieldErrorMessage
        return false
      } else {
        this.evidenceIdErrors[fieldName] = null
      }
      return true
    },

    updateBranchIndicator(branchIndicator) {
      this.newBasicDetails.branchIndicator = branchIndicator

      const nullCheckNotEqual =
        this.newBasicDetails.branchIndicator === null
          ? !isEqual(
              this.newBasicDetails.branchIndicator,
              this.basicDetails?.branchIndicator
            )
          : !isEqual(
              this.newBasicDetails.branchIndicator?.value.toString(),
              this.basicDetails?.branchIndicator?.toString()
            )

      const valueCheckNotEqual =
        typeof this.newBasicDetails.branchIndicator === 'object' &&
        this.newBasicDetails.branchIndicator !== null &&
        this.newBasicDetails.branchIndicator !== undefined &&
        !isEqual(
          this.newBasicDetails.branchIndicator?.value.toString(),
          this.basicDetails?.branchIndicator?.toString()
        )

      if (nullCheckNotEqual || valueCheckNotEqual) {
        this.removeEvidence(basicDetailEvidenceId.BRANCH_INDICATOR)
      }
    },

    updateUSGseFlag(usGseFlag) {
      // Implement the logic to update usGseFlag
      this.newBasicDetails.usGseFlag = usGseFlag

      const nullCheckNotEqual =
        this.newBasicDetails.usGseFlag === null
          ? !isEqual(
              this.newBasicDetails.usGseFlag,
              this.basicDetails?.usGseFlag
            )
          : !isEqual(
              this.newBasicDetails.usGseFlag?.value.toString(),
              this.basicDetails?.usGseFlag?.toString()
            )

      const valueCheckNotEqual =
        typeof this.newBasicDetails.usGseFlag === 'object' &&
        this.newBasicDetails.usGseFlag !== null &&
        this.newBasicDetails.usGseFlag !== undefined &&
        !isEqual(
          this.newBasicDetails.usGseFlag?.value.toString(),
          this.basicDetails?.usGseFlag?.toString()
        )

      if (nullCheckNotEqual || valueCheckNotEqual) {
        this.removeEvidence(basicDetailEvidenceId.US_GSE_FLAG)
      }
    },

    async updateBusinessStatus(businessStatus) {
      this.newBasicDetails = {
        ...this.newBasicDetails,
        businessStatus: businessStatus,
        businessStatusInactiveReason: null
      }
      if (
        !isEqual(
          this.newBasicDetails.businessStatus,
          this.basicDetails?.businessStatus
        ) ||
        (typeof this.newBasicDetails.businessStatus === 'object' &&
          this.newBasicDetails.businessStatus !== null &&
          this.newBasicDetails.businessStatus !== undefined &&
          !isEqual(
            this.newBasicDetails.businessStatus?.value.toString(),
            this.basicDetails?.businessStatus?.toString()
          ))
      ) {
        this.removeEvidence(basicDetailEvidenceId.BUSINESS_STATUS)
      }
      if (businessStatus) {
        const isActive = businessStatus && businessStatus.value === true
        const reasonCode = isActive ? 'ACTIVE' : 'INACTIVE'
        this.getInactivationReason(reasonCode)
      } else {
        this.updateInactiveReasonList([])
      }
    },

    updateBusinessStatusInactiveReason(inactiveReason) {
      this.newBasicDetails.businessStatusInactiveReason = inactiveReason
    },

    async updateEntityType(entityType) {
      this.newBasicDetails.entityTypeValue = entityType
      this.newBasicDetails.entitySubTypeValue = null

      const nullCheckNotEqual =
        this.newBasicDetails.entityTypeValue === null
          ? !isEqual(
              this.newBasicDetails.entityTypeValue,
              this.basicDetails?.entityTypeId
            )
          : !isEqual(
              this.newBasicDetails.entityTypeValue?.value.toString(),
              this.basicDetails?.entityTypeId?.toString()
            )

      const valueCheckNotEqual =
        typeof this.newBasicDetails.entityTypeValue === 'object' &&
        this.newBasicDetails.entityTypeValue !== null &&
        this.newBasicDetails.entityTypeValue !== undefined &&
        !isEqual(
          this.newBasicDetails.entityTypeValue?.value.toString(),
          this.basicDetails?.entityTypeId?.toString()
        )

      if (nullCheckNotEqual || valueCheckNotEqual) {
        this.removeEvidence(basicDetailEvidenceId.ENTITY_TYPE)
      }

      // populate entity subtype list
      if (entityType) {
        this.getEntitySubTypeList(entityType.value)
      } else {
        this.updateEntitySubTypeList([])
      }
    },

    updateEntitySubType(entitySubType) {
      this.newBasicDetails.entitySubTypeValue = entitySubType

      const nullCheckNotEqual =
        this.newBasicDetails.entitySubTypeValue === null
          ? !isEqual(
              this.newBasicDetails.entitySubTypeValue,
              this.basicDetails?.entitySubTypeId
            )
          : !isEqual(
              this.newBasicDetails.entitySubTypeValue?.value.toString(),
              this.basicDetails?.entitySubTypeId?.toString()
            )

      const valueCheckNotEqual =
        typeof this.newBasicDetails.entitySubTypeValue === 'object' &&
        this.newBasicDetails.entitySubTypeValue !== null &&
        this.newBasicDetails.entitySubTypeValue !== undefined &&
        !isEqual(
          this.newBasicDetails.entitySubTypeValue?.value.toString(),
          this.basicDetails?.entitySubTypeId?.toString()
        )

      if (nullCheckNotEqual || valueCheckNotEqual) {
        this.removeEvidence(basicDetailEvidenceId.ENTITY_SUB_TYPE)
      }
    },

    updateEntityName(entityName) {
      this.newBasicDetails.legalEntityName = entityName
      this.removeEvidence(basicDetailEvidenceId.LEGAL_ENTITY_NAME)
    },

    updatePreviousEntityName(previousEntityName) {
      this.newBasicDetails.previousEntityName = previousEntityName || null
      this.removeEvidence(basicDetailEvidenceId.PREVIOUS_ENTITY_NAME)
    },

    updateAlias(alias) {
      this.newBasicDetails.alias = alias || null
      this.removeEvidence(basicDetailEvidenceId.ALIAS)
    },

    updateLegalStructure(legalStructure) {
      this.newBasicDetails.legalStructure = legalStructure

      const nullCheckNotEqual =
        this.newBasicDetails.legalStructure === null
          ? !isEqual(
              this.newBasicDetails.legalStructure,
              this.basicDetails?.legalStructure
            )
          : !isEqual(
              this.newBasicDetails.legalStructure?.value.toString(),
              this.basicDetails?.legalStructure?.toString()
            )

      const valueCheckNotEqual =
        typeof this.newBasicDetails.legalStructure === 'object' &&
        this.newBasicDetails.legalStructure !== null &&
        this.newBasicDetails.legalStructure !== undefined &&
        !isEqual(
          this.newBasicDetails.legalStructure?.value.toString(),
          this.basicDetails?.legalStructure?.toString()
        )

      if (nullCheckNotEqual || valueCheckNotEqual) {
        this.removeEvidence(basicDetailEvidenceId.LEGAL_STRUCTURE)
      }
    },

    async toggleEvidenceForm(inputName) {
      const isViewEvidence = this.getEvidenceFormStatus(inputName)
      const evidenceIds = this.getEvidenceIds(inputName)

      this.$emit('toggleEvidenceForm', {
        inputName,
        isViewEvidence,
        evidenceIds: evidenceIds || []
      })
    },

    getEvidenceIds(evidenceId) {
      return this.inputNameMapping[`${evidenceId}`]
    },

    updateInputNameMapping(inputName, evidenceId) {
      this.$set(this.inputNameMapping, inputName, evidenceId)
    },

    isEvidenceBtnDisabled(inputName) {
      let originalChoice
      const evidenceIdMapping = {
        entityTypeValue: this.newBasicDetails.entityTypeEvidenceId,
        entitySubTypeValue: this.newBasicDetails.entitySubTypeEvidenceId,
        legalStructure: this.newBasicDetails.legalStructureEvidenceId,
        businessStatus: this.newBasicDetails.businessStatusEvidenceId,
        usGseFlag: this.newBasicDetails.usGseFlagEvidenceId,
        branchIndicator: this.newBasicDetails.branchIndicatorEvidenceId,
        legalEntityName: this.newBasicDetails.legalEntityNameEvidenceId,
        previousEntityName: this.newBasicDetails.previousEntityNameEvidenceId,
        alias: this.newBasicDetails.aliasEvidenceId
      }
      if (inputName === 'entityTypeValue') {
        originalChoice =
          this.entityTypeList.find((entityType) => {
            return entityType.value === this.basicDetails.entityTypeId
          }) ?? null
      }

      if (inputName === 'entitySubTypeValue') {
        originalChoice =
          this.entitySubTypeList.find((entitySubType) => {
            return entitySubType.value === this.basicDetails.entitySubTypeId
          }) ?? null
      }
      if (inputName === 'legalStructure') {
        originalChoice =
          this.legaStructures.find((legalStruct) => {
            return legalStruct.value == this.basicDetails.legalStructure
          }) ?? null
        originalChoice = originalChoice === '' ? null : originalChoice
      }
      if (inputName === 'businessStatus') {
        const businessStatus =
          this.basicDetails &&
          this.basicDetails.businessStatus &&
          typeof this.basicDetails.businessStatus === 'object' &&
          'value' in this.basicDetails.businessStatus
            ? this.basicDetails.businessStatus.value
            : this.basicDetails.businessStatus
        originalChoice =
          this.businessStatusOptions.find((option) => {
            return option.value === businessStatus
          }) ?? null
      }
      if (inputName === 'businessStatusInactiveReason') {
        originalChoice =
          this.inactivationReasons.find((reason) => {
            return (
              reason.value === this.basicDetails.businessInactivationReasonId
            )
          }) ?? null
      }
      if (inputName === 'usGseFlag') {
        originalChoice =
          this.enrichmentAnswerChoices.find((choice) => {
            return choice.value === this.basicDetails.usGseFlag
          }) ?? null
      }
      if (inputName === 'branchIndicator') {
        originalChoice =
          this.enrichmentAnswerChoices.find((choice) => {
            return choice.value === this.basicDetails.branchIndicator
          }) ?? null
      }

      const isDropDown = [
        'entityTypeValue',
        'entitySubTypeValue',
        'legalStructure',
        'businessStatus',
        'businessStatusInactiveReason',
        'usGseFlag',
        'branchIndicator'
      ].includes(inputName)

      const isEvidenceAltered = this.isEvidenceChanged(inputName)

      if (isDropDown) {
        // Check if originalChoice is an object and has a key "value" and is not null
        const choiceValue =
          originalChoice &&
          typeof originalChoice === 'object' &&
          'value' in originalChoice
            ? originalChoice.value
            : originalChoice
        const newBasicDetailsFieldValue =
          this.newBasicDetails[`${inputName}`] &&
          typeof this.newBasicDetails[`${inputName}`] === 'object' &&
          'value' in this.newBasicDetails[`${inputName}`]
            ? this.newBasicDetails[`${inputName}`].value
            : null
        return (
          !evidenceIdMapping[`${inputName}`] &&
          newBasicDetailsFieldValue === choiceValue &&
          !isEvidenceAltered
        )
      } else {
        return (
          !evidenceIdMapping[`${inputName}`] &&
          this.newBasicDetails[`${inputName}`] ===
            (this.basicDetails ? this.basicDetails[`${inputName}`] : '') &&
          !isEvidenceAltered
        )
      }
    },

    isEvidenceChanged(inputName) {
      switch (inputName) {
        case 'entityTypeValue':
          return (
            this.newBasicDetails.entityTypeEvidenceId !==
            (this.basicDetails ? this.basicDetails.entityTypeEvidence : null)
          )
        case 'entitySubTypeValue':
          return (
            this.newBasicDetails.entitySubTypeEvidenceId !==
            (this.basicDetails ? this.basicDetails.entitySubTypeEvidence : null)
          )
        case 'legalStructure':
          return (
            this.newBasicDetails.legalStructureEvidenceId !==
            (this.basicDetails
              ? this.basicDetails.legalStructureEvidence
              : null)
          )
        case 'businessStatus':
          return (
            this.newBasicDetails.businessStatusEvidenceId !==
            (this.basicDetails
              ? this.basicDetails.businessStatusEvidence
              : null)
          )
        case 'usGseFlag':
          return (
            this.newBasicDetails.usGseFlagEvidenceId !==
            (this.basicDetails ? this.basicDetails.usGseFlagEvidence : null)
          )
        case 'branchIndicator':
          return (
            this.newBasicDetails.branchIndicatorEvidenceId !==
            (this.basicDetails
              ? this.basicDetails.branchIndicatorEvidence
              : null)
          )
        case 'legalEntityName':
          return (
            this.newBasicDetails.legalEntityNameEvidenceId !==
            (this.basicDetails
              ? this.basicDetails.legalEntityNameEvidence
              : null)
          )
        case 'previousEntityName':
          return (
            this.newBasicDetails.previousEntityNameEvidenceId !==
            (this.basicDetails
              ? this.basicDetails.previousEntityNameEvidence
              : null)
          )
        case 'alias':
          return (
            this.newBasicDetails.aliasEvidenceId !==
            (this.basicDetails ? this.basicDetails.aliasEvidence : null)
          )

        default:
          return false
      }
    },

    getTooltipMsg(inputName) {
      return `Multiple values allowed separated by Pipe(|). E.g: ${inputName}1 | ${inputName}2`
    },

    getKeyByValue(obj, value) {
      return Object.entries(obj).reduce((acc, [key, val]) => {
        if (val === value) {
          acc.push(key)
        }
        return acc
      }, [])
    }
  }
}
</script>

<style lang="scss">
.basic-details-section {
  margin-top: 1rem;
  position: relative;

  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;

    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .section-title {
    font-size: 21px;
    font-weight: 600;
    color: #00218a;
  }

  .add-evidence {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .mandatory-note {
    p {
      font-size: 12px;
      font-weight: normal;
      font-family: Quicksand;
      letter-spacing: 0.14px;
    }

    .mandatory {
      color: red;
      font-size: 17px;
    }
  }

  .basic-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;

    .column-view {
      width: 45%;
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      .required-error {
        position: relative;
      }

      .radio-toolbar {
        p {
          text-align: left;
          font-size: 12px;
          font-family: Quicksand;
          font-weight: normal;
          font-weight: 600;
          letter-spacing: 0px;
          color: #5b5b5b;
          opacity: 1;
        }

        label {
          text-align: left;
          font-size: 12px;
          font-family: Quicksand;
          font-weight: 600;
          letter-spacing: 0px;
          color: #7d7d7d;
          opacity: 1;
          margin-left: 0.25rem;
        }

        #radio2 {
          margin-left: 1.25rem;
        }

        #radio4 {
          margin-left: 1.25rem;
        }

        input {
          border: 1px solid #707070;
          opacity: 1;
          margin-top: 0.75rem;
        }
      }

      .form-group {
        width: 100%;

        p {
          display: flex;
          margin: 0px;
          color: #7d7d7d;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          display: flex;
          line-height: 20px;
          min-height: 21px;
          margin-bottom: 0.15rem;
        }

        .input {
          text-align: left;
          font: normal normal 13px/23px Quicksand;
          font-weight: 600;
          color: #1e1e1e;
          opacity: 1;
          border-color: #cccccc;
        }
      }

      .form-control-disabled > .form-control {
        width: 100%;
        background-color: rgb(83, 94, 235, 0.1);

        p {
          display: flex;
          margin: 0px;
          color: #7d7d7d;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          display: flex;
          line-height: 20px;
          min-height: 21px;
          margin-bottom: 0.15rem;
        }

        .input {
          text-align: left;
          letter-spacing: 0.18px;
          color: #00218a !important;
          opacity: 0.9;
          font: normal normal bold 13px/23px Quicksand;
          font-weight: 600;
          width: 100%;
        }
      }

      .form-input-field {
        width: 75%;
      }

      .Searchable-select {
        width: 75%;
      }

      .add-evidence {
        width: 25%;
        margin-top: 1.5rem;
      }
    }
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .basic-details-section {
    border-radius: 6px;

    .section-title {
      font-size: 24px;
    }

    .mandatory-note {
      p {
        font: normal normal normal 13px/16px Quicksand;
        letter-spacing: 0.14px;
      }
    }

    .basic-form {
      width: 97%;

      .column-view {
        width: 45%;

        .radio-toolbar {
          p {
            font: normal normal 14px/15px Quicksand;
            font-weight: 600;
          }

          label {
            font: normal normal 14px/23px Quicksand;
            font-weight: 600;
          }
        }

        .form-group {
          p {
            font-size: 14px;
            min-height: 25px;
          }

          .input {
            font: normal normal 16px/23px Quicksand;
            font-weight: 600;
          }
        }

        .form-control-disabled > .form-control {
          p {
            font-size: 14px;
            min-height: 25px;
          }

          .input {
            font: normal normal 16px/23px Quicksand;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media (min-width: 1700px) {
  .basic-details-section {
    .section-title {
      font-size: 26px;
    }

    .mandatory-note {
      p {
        font: normal normal normal 14px/18px Quicksand;
        letter-spacing: 0.14px;
      }
    }

    .basic-form {
      width: 97%;

      .column-view {
        width: 45%;

        .radio-toolbar {
          p {
            font: normal normal 16px/15px Quicksand;
            font-weight: 600;
          }

          label {
            font: normal normal 16px/23px Quicksand;
            font-weight: 600;
          }
        }

        .form-group {
          p {
            font-size: 16px;
            min-height: 25px;
          }

          .input {
            font: normal normal 18px/23px Quicksand;
            font-weight: 600;
          }
        }

        .form-control-disabled > .form-control {
          p {
            font-size: 16px;
            min-height: 25px;
          }

          .input {
            font: normal normal 18px/23px Quicksand;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .basic-details-section {
    border-radius: 6px;

    .section-title {
      font-size: 24px;
    }

    .mandatory-note {
      p {
        font: normal normal normal 13px/16px Quicksand;
        letter-spacing: 0.14px;
      }
    }

    .basic-form {
      width: 97%;

      .column-view {
        width: 105%;

        .radio-toolbar {
          p {
            font: normal normal 14px/15px Quicksand;
            font-weight: 600;
          }

          label {
            font: normal normal 14px/23px Quicksand;
            font-weight: 600;
          }
        }

        .form-group {
          p {
            font-size: 14px;
            min-height: 25px;
          }

          .input {
            font: normal normal 16px/23px Quicksand;
            font-weight: 600;
          }
        }

        .form-control-disabled > .form-control {
          p {
            font-size: 14px;
            min-height: 25px;
          }

          .input {
            font: normal normal 16px/23px Quicksand;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
