<template>
  <div>
    <Dialog
      v-if="visibleDuplicateDialog"
      :title="'Duplicate Entity Found'"
      @close="closeDialog"
      :width="dialogProps.width"
      :height="dialogProps.height"
    >
      <p>We already have the same entity :</p>

      <DialogActionsBar>
        <div class="action-buttons">
          <b-button class="action-button cancel-button" @click="closeDialog">
            Cancel
          </b-button>
        </div>
      </DialogActionsBar>
      <div>
        <kendo-grid
          :data-items="paginatedData"
          :columns="columns"
          :pageable="true"
          :total="duplicateLegalNames.length"
          :skip="page.skip"
          :page-size="page.pageSize"
          @pagechange="pageChange"
          class="custom-grid-header"
        >
          <kendo-grid-column field="sgaId" title="Sga ID"></kendo-grid-column>
          <kendo-grid-column
            field="entityName"
            title="Legal Entity Name"
          ></kendo-grid-column>
          <kendo-grid-column
            field="countryName"
            title="Country Of Domicile"
          ></kendo-grid-column>
          <kendo-grid-column
            field="regCountryName"
            title="Country of Registration"
          ></kendo-grid-column>
        </kendo-grid>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { Grid, GridColumn } from '@progress/kendo-vue-grid'

export default {
  name: 'DuplicatePopup',
  components: {
    Dialog,
    DialogActionsBar,
    'kendo-grid': Grid,
    'kendo-grid-column': GridColumn
  },
  watch: {
    duplicateLegalNames(newVal) {
      if (newVal && newVal.length > 0) {
        this.visibleDuplicateDialog = this.isDuplicateGridVisible
        this.page.skip = 0
      } else {
        this.visibleDuplicateDialog = false
      }
    }
  },
  data() {
    return {
      dialogProps: {
        width: 1050,
        height: 450
      },
      page: {
        currentPage: 0,
        skip: 0,
        pageSize: 5
      },
      columns: [
        {
          field: 'entityName',
          title: 'Legal Entity Name'
        },
        {
          field: 'sgaId',
          title: 'SGA Id'
        },

        {
          field: 'countryCode',
          title: 'Country of Domicile'
        },
        {
          field: 'regCountryCode',
          title: 'Country of Registration'
        }
      ],
      visibleDuplicateDialog: false
    }
  },
  props: {
    duplicateLegalNames: {
      type: Array,
      required: true,
      default: () => []
    },
    isDuplicateGridVisible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    paginatedData() {
      return this.duplicateLegalNames.slice(
        this.page.skip,
        this.page.skip + this.page.pageSize
      )
    }
  },
  methods: {
    closeDialog() {
      this.visibleDuplicateDialog = !this.visibleDuplicateDialog
    },
    pageChange(event) {
      this.page.skip = event.page.skip
      this.page.pageSize = event.page.take
    }
  }
}
</script>
<style scoped>
.action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  height: 5rem;
  padding-bottom: 0.6rem;
  width: 100%;
}
.action-button {
  color: #424242;
  font-size: 16px;
  font-weight: 600;
  min-width: 104px;
  height: 46px;
}

.cancel-button {
  background-color: #cccccc;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-origin: padding-box;

  border-radius: 8px;
  padding: 8px;
  opacity: 1;
  text-align: center;
  font-size: 16px;
  line-height: 17px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #444444;
  opacity: 1;
}
.cancel-button:hover {
  background-color: #cccccc;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-origin: padding-box;
  opacity: 0.9;
}
::v-deep .custom-grid-header .k-grid-header {
  background-color: #cccccc;
  color: white;
}

::v-deep .custom-grid-header .k-header {
  font-weight: bold;
  text-align: center;

  font-size: 16px;
  color: #000000;
  line-height: 19px;
}
</style>
