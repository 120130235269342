<template>
  <div class="entities-container">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <EntityFormHeader
      :reviewMode="isReviewSubmit"
      @onSubmit="submitForReview"
      @onEdit="backToForm"
      @onConfirm="onConfirm"
      ref="entityHeaderDetails"
      v-if="!isAddEntitiesEnabled"
    />

    <!-- main form container -->
    <div class="form-container">
      <div class="tab-container">
        <div
          class="worktype-container"
          v-if="!isAddEntitiesEnabled && !isReviewSubmit"
        >
          <p>
            Work type: <span>{{ workType }}</span>
          </p>
        </div>
        <TabStrip
          v-if="!isAddEntitiesEnabled && !isReviewSubmit"
          :selected="selected"
          @select="onSelect"
          :tabs="tabs"
        >
          <template v-slot:TabOne>
            <div class="entity-details-container">
              <EntityDetails
                :auditDetails="auditDetails"
                ref="enrichmentForm"
              ></EntityDetails>
            </div>
          </template>
          <template v-slot:TabTwo>
            <RelationshipHierarchy
              :relationshipHierarchy="relationshipHierarchy"
              @backToEnrichForm="backToEnrichForm"
              :errorMsg="'Failed to fetch hierarchy details. Please try again!'"
            ></RelationshipHierarchy>
          </template>
          <template v-slot:TabThree>
            <CorporateActions
              :caDetails="caDetails"
              :changeTabStrip="changeTabStrip"
            ></CorporateActions>
          </template>
          <template v-slot:TabFour>
            <audit></audit>
          </template>
          <template v-slot:TabFive>
            <p>Coming Soon...</p>
          </template>
        </TabStrip>
      </div>
    </div>

    <!-- Submit for Review component -->
    <div class="form-review-container" v-if="isReviewSubmit">
      <EntityDetailsReview ref="submitReviewForm"></EntityDetailsReview>
    </div>

    <ConfirmationDialog
      class="mandatory-popup"
      :visible="dialog.visible"
      :title="dialog.title"
      :bodyText="dialog.text"
      :cancelButtonText="dialog.cancelButtonText"
      :confirmButtonText="dialog.confirmButtonText"
      :closeDialog="closeDialog"
      :triggerFunction="dialog.triggerFunction"
    />

    <relationshipHierarchyDialog
      class="mandatory-popup"
      :visible="hierarchyDialog.visible"
      :title="hierarchyDialog.title"
      :bodyText="hierarchyDialog.text"
      :cancelButtonText="hierarchyDialog.cancelButtonText"
      :confirmButtonText="hierarchyDialog.confirmButtonText"
      :closeDialog="closeDialog"
      :triggerFunction="hierarchyDialog.triggerFunction"
      @backToEnrichForm="backToEnrichFormFromPopup"
    />
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import EntityFormHeader from '@/components/DMP/EntityFormHeader.vue'
import Snackbar from '@/components/Snackbar'
import { checkPathPermission } from '@/util/permissions.utils'
import Audit from '@/views/DataManagement/Audit/Audit.vue'
import CorporateActions from '@/views/DataManagement/CorporateAction/CorporateAction.vue'
import EntityDetails from '@/views/DataManagement/EntityDetails.vue'
import EntityDetailsReview from '@/views/DataManagement/EntityDetailsReview.vue'
import relationshipHierarchyDialog from '@/views/DataManagement/relationshipHierarchyDialog.vue'
import RelationshipHierarchy from '@/views/DataManagement/RelationshipHierarchy.vue'
import { TabStrip } from '@progress/kendo-vue-layout'
import { mapActions, mapGetters, mapState } from 'vuex'
import { entityStatus } from '@/constant/data.js'

export default {
  name: 'DMPEntities',
  components: {
    EntityFormHeader,
    TabStrip,
    EntityDetails,
    Audit,
    EntityDetailsReview,
    ConfirmationDialog,
    RelationshipHierarchy,
    relationshipHierarchyDialog,
    CorporateActions
  },
  data() {
    return {
      isEnabled: false,
      sgaId: '',
      selected: 0,
      isReviewSubmit: false,
      loading: false,
      workType: null,
      isDialogOpen: false,

      // confirmation popup
      dialog: {
        visible: false,
        title: 'Missing Mandatory Details',
        text: '',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Edit Form',
        triggerFunction: () => {}
      },

      hierarchyDialog: {
        visible: false,
        title: 'Broken Hiearchy',
        text: '',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Edit Form',
        triggerFunction: () => {}
      },

      tabs: [
        {
          title: 'Entity Details',
          content: 'TabOne'
        },
        {
          title: 'Hierarchy/Relationships Details',
          content: 'TabTwo'
        },
        {
          title: 'Corporate Actions',
          content: 'TabThree'
        },
        {
          title: 'Audit Details',
          content: 'TabFour'
        },
        {
          title: 'Version History',
          content: 'TabFive'
        }
      ],

      snackbarPayload: {
        // Set default payload
        message: null,
        type: 'is-warning',
        duration: 3000
      }
    }
  },
  computed: {
    ...mapState('dmp', ['isAddEntitiesEnabled']),
    ...mapState('user', ['userDetails']),
    ...mapState('assignedEntities', ['entityDetails']),
    ...mapState('audit', ['auditDetails']),
    ...mapState('relationship', ['relationshipHierarchy', 'failedToFetchFlag']),
    ...mapState('corporateAction', ['caDetails']),
    ...mapGetters('dmp', ['getEntityDetails', 'getStepperForm'])
  },
  mounted() {
    // Getting worktype from store
    const { workType, sgaId, isCAWorkType } = this.getEntityDetails
    this.workType = workType
    this.sgaId = sgaId
    if (isCAWorkType) {
      this.changeTabStrip(2)
    }
  },
  methods: {
    ...mapActions('review', ['updateEntityStatus', 'updateEntityFlag']),
    ...mapActions('dmp', [
      'updateFullAddress',
      'getAddressDetails',
      'updateEntitiesStatus'
    ]),
    ...mapActions('audit', ['getAuditDetails']),
    ...mapActions('relationship', ['getRelationships', 'updateHierarchyView']),
    ...mapActions('corporateAction', ['getCorporateAction']),
    ...mapActions('dmp', ['updateStepperForm', 'resetFormsDetails']),
    onChangeModal(value) {
      this.modal = value
    },

    onAddEntities(sgaId) {
      this.$router.push({
        name: 'addEntities',
        params: { sgaId: sgaId }
      })
    },

    onSelect(e) {
      this.selected = e.selected
      const { sgaId } = this.getEntityDetails
      if (e.selected === 3) {
        this.getAuditDetails(sgaId)
      } else if (e.selected === 2) {
        this.getCorporateAction(sgaId)
      } else if (e.selected === 1) {
        this.updateHierarchyView([])
        this.getRelationships({
          sgaId: sgaId,
          hierarchyTree: true
        })
        this.$refs.entityHeaderDetails.getHeaderDetails()
      }
    },

    submitForReview(isSubmit) {
      this.isReviewSubmit = isSubmit
    },
    async backToEnrichForm(data) {
      await this.prepareDataForForm(data)
      this.$refs.entityHeaderDetails.getHeaderDetails()
      const stepperIndex = this.getStepperForm ? this.getStepperForm : 0
      this.$refs.enrichmentForm.handleChange(stepperIndex, true, true)
      await this.backToForm()
    },

    async prepareDataForForm(data) {
      const { sgaId, assignmentId, workType } = data
      let redirectionWorktype = null
      const workTypeOrder = [
        'New Onboarding',
        'Data Enrichment',
        'Periodic Review',
        'Corporate Action'
      ]
      for (let index = 0; index < workTypeOrder.length; index++) {
        if (workType && workType.includes(workTypeOrder[index])) {
          redirectionWorktype = workTypeOrder[index]
          break
        }
      }
      redirectionWorktype = redirectionWorktype || 'Data Enrichment'
      this.hierarchyDialog.visible = false
      await this.updateStepperForm(null)
      await this.updateEntitiesStatus({
        entityStatus: false,
        entityDetails: {
          sgaId,
          workType: redirectionWorktype,
          assignmentId
        }
      })
    },
    async backToEnrichFormFromPopup(data) {
      await this.prepareDataForForm(data)
      await this.$refs.entityHeaderDetails.getHeaderDetails()
      this.backToForm()
    },

    async backToForm(isEdit) {
      this.updateHierarchyView([])
      this.isReviewSubmit = isEdit
      this.selected = 0 // back to entity details tab
    },
    checkHierarchyValidation() {
      return this.checkParentsStatus(
        this.relationshipHierarchy,
        this.entityDetails[0]?.entityName
      )
    },

    async onConfirm() {
      const { isMandatoryPassed, errorFields } =
        this.$refs.submitReviewForm.mandatoryValidation()
      if (isMandatoryPassed) {
        // To Do: action to be performed
        // make api call for submit for review
        // redirect to assigned entities page
        const { assignmentId } = this.getEntityDetails
        const entityStatusData = {
          assignmentId: assignmentId,
          userRole: this.userDetails.roleName,
          currentStatus: this.entityDetails[0]?.currentStatus
        }

        try {
          this.loading = true
          let isSuccess = false
          this.updateHierarchyView([])
          const { sgaId } = this.getEntityDetails

          if (
            checkPathPermission(
              'submit-for-delivery',
              this.userDetails.roleName
            )
          ) {
            await this.getRelationships({
              sgaId: sgaId,
              hierarchyTree: true
            })
            if (!this.checkHierarchyValidation()) {
              this.hierarchyDialog.visible = true
              return
            }
            const entityFlagObj = {
              sgaId: sgaId,
              flag: false
            }
            // Verify the response of update submit-for-delivery flag
            isSuccess = await this.updateEntityFlag(entityFlagObj)
            if (isSuccess) {
              const addressObj = {
                sgaId: sgaId,
                fullAddress: '##CLEAR##'
              }
              await this.updateFullAddress(addressObj)
              await this.getAddressDetails(sgaId)
            }
          }

          isSuccess = await this.updateEntityStatus(entityStatusData)

          if (isSuccess) {
            this.$router.go(-1)
            this.snackbarPayload.type = 'is-success'
            this.snackbarPayload.message = 'Details submitted successfully.'
          } else {
            // In-case of update failure
            throw new Error()
          }
        } catch (error) {
          // show dialog with error msg for hierarchy api only
          if (this.failedToFetchFlag) {
            this.hierarchyDialog.visible = true
            return
          } else {
            this.snackbarPayload.type = 'is-danger'
            this.snackbarPayload.message = 'Failed to submit the Details.'
          }
        } finally {
          this.loading = false
        }
        Snackbar(this.snackbarPayload)
      } else {
        // show warning msg
        let popUpMsg = ''
        for (const formName in errorFields) {
          const title = `<br> <br> <strong> ${formName}</strong> <br>`
          const errorMsg = errorFields[formName].join('<br>')
          popUpMsg += `${title} ${errorMsg}`
        }
        this.dialog.visible = true
        this.dialog.text = `Please provide below mandatory details: ${popUpMsg}`
        this.dialog.triggerFunction = () => {
          // User confirmed, go back to edit form
          this.backToForm()
        }
      }
    },

    closeDialog() {
      this.dialog.visible = false
      this.hierarchyDialog.visible = false
    },

    changeTabStrip(strapIndex) {
      this.selected = strapIndex
    },

    checkParentsStatus(data, entityName) {
      // Multiple Hierarchy assumed as Broken Hieararchy
      if (data && data[0]?.isImmediateParentBrokenHiearchy) {
        return false
      }

      function traverseAndCheck(node) {
        // Check if the current node matches the entityName
        let currentNode
        if (node.legalEntityName === entityName) {
          // Traverse upwards to check parent statuses ,
          // submit and staging
          currentNode = findParentNode(data, node.immediateParentId)
          while (currentNode) {
            // If the status is not "Submitted", return false
            if (
              currentNode.legalEntityName !== entityName &&
              currentNode.status !== entityStatus.STAGING &&
              currentNode.status !== entityStatus.SUBMIT
            ) {
              return false
            }
            // If currentNode is the ultimate parent, break the loop
            if (currentNode.is_ultimate_parent) {
              break
            }

            // Find the parent node in the data
            currentNode = findParentNode(data, currentNode.immediateParentId)
          }
          return true
        }

        // If the node has child items, traverse them
        if (node.items && node.items.length > 0) {
          for (const item of node.items) {
            const result = traverseAndCheck(item)
            if (result !== undefined) {
              return result
            }
          }
        }
      }

      // Helper function to find a parent node by its ID
      function findParentNode(data, parentId) {
        function search(node) {
          if (node.entityId === parentId) {
            return node
          }

          if (node.items && node.items.length > 0) {
            for (const item of node.items) {
              const result = search(item)
              if (result) {
                return result
              }
            }
          }
          return null
        }

        for (const node of data) {
          const foundNode = search(node)
          if (foundNode) {
            return foundNode
          }
        }
        return null
      }

      // Start the traversal from the root data
      for (const node of data) {
        const result = traverseAndCheck(node)
        if (result !== undefined) {
          return result
        }
      }

      // Return false if the entityName is not found
      return data && data.length > 0 ? false : true
    }
  },
  destroyed() {
    // Reset the entity details
    this.updateEntitiesStatus({
      entityStatus: false,
      entityDetails: { sgaId: null, assignmentId: null, workType: null }
    })
  }
}
</script>
<style lang="scss" scoped>
.entities-container {
  max-width: 1450px;
  margin: 0 auto;
  background-color: var(--background-color);

  .entities-header {
    display: flex;
    align-items: center;

    .entities-add-btn {
      margin-left: auto;
    }
  }
}
</style>
<style lang="scss">
.entities-container {
  max-width: 1450px;
  margin: 0 auto;
  background-color: var(--background-color);

  .entities-header {
    display: flex;
    align-items: center;

    .entities-add-btn {
      margin-left: auto;
    }
  }
}

.tab-container {
  position: relative;

  .worktype-container {
    position: absolute;
    top: 10px;
    right: 4px;
    padding: 10px;

    p {
      font-size: 16px;
      color: black;

      span {
        font-size: 16px;
        color: var(--inava-primary-dark);
        border: 1px solid var(--inava-primary-dark);
        border-radius: 4px;
        padding: 5px;
      }
    }
  }
}

.entities-container {
  .k-tabstrip {
    font-size: 16px;
    color: #000000;
    background-color: var(--dmp-entities-tab-menu-bg-color);

    .k-tabstrip-items {
      padding: 12px;
      width: 65%;
    }

    .k-tabstrip-items-wrapper .k-item {
      color: #7d7d7d;
    }

    .k-tabstrip-items-wrapper .k-item:active,
    .k-tabstrip-items-wrapper .k-item.k-active,
    .k-tabstrip-items-wrapper .k-item.k-selected {
      border-color: rgba(0, 0, 0, 0.08);
      border-bottom-color: rgba(0, 0, 0, 0.08);
      color: #000000;
      background-color: #dfdfdf;
    }
  }
}

.entities-container {
  .mandatory-popup {
    .k-dialog-wrapper {
      .k-window-content {
        .popup-msg {
          text-align: left;
        }
      }
    }
  }
}
</style>
